// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';

const CustomBlock = (props) => {
	const { as = 'div', className, children } = props;

	return (
		<Container as={as} fluid className={className}>
			<Row>
				<Col {...props}>{children}</Col>
			</Row>
		</Container>
	);
};

export default CustomBlock;
