// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// ICONS
import { TbX } from 'react-icons/tb';
// COMPONENTS
import { Container, Row, Col, Form, Button, Dropdown, FloatingLabel, InputGroup } from 'react-bootstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
// HELPERS & SERVICES
import * as espnHelpers from '../../helper/espnHelpers';

const SportsStatisticsPage = (props) => {
	const { appInfo, user } = props;
	const [isLoading, setIsLoading] = useState(false);
	// PLAYER SEARCH
	const [playerName, setPlayerName] = useState('');
	const [playerMatch, setPlayerMatch] = useState([]);
	// NFL PLAYER SEARCH
	const [roster, setRoster] = useState([]);
	const [filteredPlayers, setFilteredPlayers] = useState([]);
	const [selectedPlayer, setSelectedPlayer] = useState(null);
	const [selectedPlayerTeam, setSelectedPlayerTeam] = useState(null);

	// EVENT SEARCH
	const [NFLEvents, setNFLEvents] = useState([]);

	useEffect(() => {
		if (!roster.length > 0) {
			getNFLRoster();
		}

		if (!getNFLCurrentEvents.length > 0) {
			getNFLCurrentEvents();
		}
	}, []);

	useEffect(() => {
		if (selectedPlayer || selectedPlayerTeam) {
			console.log(`PLAYER - ${selectedPlayer.fullName} \n`, selectedPlayer);
			console.log(`PLAYER TEAM - ${selectedPlayer.fullName} \n`, selectedPlayerTeam);
		}
	}, [selectedPlayer, selectedPlayerTeam]);

	useEffect(() => {
		if (selectedPlayer) {
			console.log(`PLAYER - ${selectedPlayer.fullName} \n`, selectedPlayer);
		}
	}, [selectedPlayer]);

	const getNFLRoster = async () => {
		setIsLoading(true);
		const TEAM_API = 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams';

		try {
			const response = await fetch(TEAM_API);
			const data = await response.json();
			const teams = data.sports[0].leagues[0].teams;

			let completeRoster = [];

			for (let team of teams) {
				const teamId = team.team.id;
				const teamName = team.team.abbreviation;

				const rosterResponse = await fetch(`${TEAM_API}/${teamId}?enable=roster`);
				const rosterData = await rosterResponse.json();

				const athletes = rosterData.team.athletes.map((athlete) => ({
					id: athlete.id,
					team: teamName,
					fullName: athlete.fullName,
				}));

				completeRoster = completeRoster.concat(athletes);
			}

			setRoster(completeRoster);
		} catch (error) {
			console.error('Error fetching NFL teams or player data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const getNFLCurrentEvents = async () => {
		const events = await espnHelpers.getNFLCurrentEvents();
		console.log('NFL EVENTS DATA \n', events);
		setNFLEvents(events);
	};

	const fetchTeamDataByAbbreviation = async (teamAbbreviation) => {
		const TEAM_API = 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams';

		try {
			const response = await fetch(TEAM_API);
			const data = await response.json();
			const teams = data.sports[0].leagues[0].teams;

			// FIND TEAM BY ABBREVIATION
			const teamData = teams.find((team) => team.team.abbreviation === teamAbbreviation);

			if (teamData) {
				return teamData.team;
			} else {
				return null;
			}
		} catch (error) {
			console.error('Error fetching team data:', error);
			return null;
		}
	};

	// const handleSearchChange = (e) => {
	// 	const searchValue = e.target.value;
	// 	setPlayerName(searchValue);

	// 	// FILTER PLAYERS BASED ON SEARCH INPUT
	// 	const matches = roster.filter((player) =>
	// 		player.fullName.toLowerCase().includes(searchValue.toLowerCase())
	// 	);

	// 	console.log(`MATCH (Total: ${matches.length}) \n`, matches);

	// 	setFilteredPlayers(matches);
	// };

	const handleSearchChange = (e) => {
		const searchValue = e.target.value.trim(); // TRIM WHITESPACE
		setPlayerName(searchValue);

		if (searchValue.length > 1) {
			// MINIMUM CHARACTERS TO SHOW DROPDOWN
			const matches = roster.filter((player) =>
				player.fullName.toLowerCase().includes(searchValue.toLowerCase())
			);
			setFilteredPlayers(matches);
		} else {
			setFilteredPlayers([]); // HIDE DROPDOWN IF LESS THAN 3 CHARACTERS
		}
	};

	const handleSelectPlayer = async (player) => {
		const playerTeam = await fetchTeamDataByAbbreviation(player.team);

		setPlayerName(player.fullName);
		setSelectedPlayer(player);
		setSelectedPlayerTeam(playerTeam);
		setFilteredPlayers([]);
	};

	return (
		<>
			{isLoading && (
				<div className=''>
					<LoadingOverlay />
					<span
						className='loading-text position-absolute start-50 translate-middle text-nowrap'
						style={{ color: '#999999', top: '63%' }}
					>
						Loading NFL Roster...
					</span>
				</div>
			)}

			<div className='statistics-page'>
				<Container className='xp-0 mt-2'>
					{/* NFL LIVE EVENTS */}
					{/* {NFLEvents.live && (
						<Row className='mb-4 g-2'>
							{NFLEvents.live.map((event) => (
								<Col key={event.id} md={6} lg={4} xl={4} className=''>
									<div
										className='card--3 rounded px-2 py-2 d-flex flex-column ug-1'
										style={{ border: '1px solid var(--bs-danger)' }}
									>
										<p className='fs-xs fw-500 d-flex align-items-center flex-wrap ug-1 lh-normal mb-1'>
											<span className='badge bg-danger text-white round fs-xxxs lh-normal px-1 py-0'>
												LIVE
											</span>{' '}
											{event.shortName}{' '}
											<span className='fs-xxs text-muted'>{event.status.type.shortDetail}</span>
										</p>

										{event.links && (
											<div className='d-flex flex-row flex-wrap justify-content-start ug-1'>
												{event.links.map((link, i) => (
													<Button
														key={`${link.text}-${i}`}
														variant='outline-primary'
														size='sm'
														className='fs-xxxs p-1 py-0'
														onClick={() => window.open(link.href, '_blank')}
													>
														{link.text}
													</Button>
												))}
											</div>
										)}
									</div>
								</Col>
							))}

							{NFLEvents.upcoming &&
								NFLEvents.upcoming.map((event) => (
									<Col key={event.id} md={6} lg={4} xl={4} className=''>
										<div className='card--3 rounded px-2 py-2 d-flex flex-column ug-1'>
											<p className='fs-xs fw-500 d-flex align-items-center flex-wrap ug-1 lh-normal mb-1'>
												{event.shortName}{' '}
												<span className='fs-xxs text-muted'>{event.status.type.shortDetail}</span>
												<img src={event.competitions} />
											</p>

											{event.links && (
												<div className='d-flex flex-row flex-wrap justify-content-start ug-1'>
													{event.links.map((link, i) => (
														<Button
															key={`${link.text}-${i}`}
															variant='outline-primary'
															size='sm'
															className='fs-xxxs p-1 py-0'
															onClick={() => window.open(link.href, '_blank')}
														>
															{link.text}
														</Button>
													))}
												</div>
											)}
										</div>
									</Col>
								))}
						</Row>
					)} */}

					{/* PLAYER SEARCH */}
					<Row>
						<Col md={8} lg={6} xl={5}>
							<Form>
								<InputGroup className='mb-1'>
									<FloatingLabel label='NFL Player Search' className='text-secondary fs-xxs'>
										<Form.Control
											type='text'
											placeholder='Player Name'
											value={playerName}
											onChange={handleSearchChange}
											className='fs-xs'
										/>
									</FloatingLabel>
									<Button
										variant='outline-secondary'
										className='fs-xxs'
										style={{ borderColor: 'var(--bs-gray-300)' }}
										disabled={isLoading || playerName.length === 0}
									>
										Search
									</Button>
									<Button
										variant='outline-danger'
										className='px-2'
										style={{ borderColor: 'var(--bs-gray-300)' }}
										disabled={isLoading || playerName.length === 0}
										onClick={() => {
											setPlayerName('');
											setSelectedPlayer(null);
											setSelectedPlayerTeam(null);
											setFilteredPlayers([]);
										}}
									>
										<TbX className='fs-sm' />
									</Button>
								</InputGroup>

								{/* FILTERED PLAYERS DROPDOWN */}
								{filteredPlayers.length > 0 && (
									<Dropdown>
										<Dropdown.Menu
											show
											className='fs-xs'
											style={{ maxHeight: '400px', overflowY: 'scroll', borderColor: 'var(--bs-gray-300)' }}
										>
											{filteredPlayers.map((player) => (
												<Dropdown.Item key={player.id} onClick={() => handleSelectPlayer(player)}>
													{player.fullName} - {player.team}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								)}
							</Form>
						</Col>
					</Row>

					<Row>
						{selectedPlayer && (
							<Col>
								{/* <div className='card--2 rounded mt-3 p-2'> */}
								<div className='mt-5'>
									<div className='d-flex align-items-center flex-wrap ug-2'>
										<img
											src={selectedPlayerTeam.logos[0].href}
											alt={selectedPlayer.fullName}
											className='h-120 rounded p-2'
										/>

										<div className='d-flex flex-column ug-1'>
											<p className='fs-sm fw-600'>
												<a
													href={`https://www.espn.com/nfl/player/_/id/${
														selectedPlayer.id
													}/${selectedPlayer.fullName
														.toLowerCase()
														.replace(/[^a-zA-Z0-9 ]/g, '')
														.split(' ')
														.join('-')}`}
												>
													{selectedPlayer.fullName}
												</a>{' '}
												<span className='fs-xs text-muted fw-400' title='Player ID'>
													{selectedPlayer.id}
												</span>
											</p>

											<div className='d-flex flex-column flex-wrap ug-1'>
												<div className='d-flex align-items-center flex-wrap ug-1'>
													<p className='fs-xxs fw-600'>{selectedPlayerTeam.displayName}</p>
													<p className='fs-xxs text-muted'>{selectedPlayerTeam.abbreviation}</p>
													<p className='fs-xxs text-muted'>{selectedPlayerTeam.id}</p>
												</div>
												<div className='d-flex align-items-center flex-wrap ug-1'>
													{/* <p className='fs-xxs text-muted'>{selectedPlayer.team}</p> */}
													<div
														className='w-30 h-15 rounded-pill'
														style={{
															backgroundColor: `#${selectedPlayerTeam.color}`,
															boxShadow:
																'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
														}}
													/>
													<div
														className='w-30 h-15 rounded-pill'
														style={{
															backgroundColor: `#${selectedPlayerTeam.alternateColor}`,
															boxShadow:
																'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
														}}
													/>
												</div>
											</div>

											<div className='d-flex align-items-center flex-wrap ug-2 mt-2'>
												{selectedPlayerTeam.links.map((link, i) => (
													<Button
														key={`${link.text}-${i}`}
														variant='outline-primary'
														size='sm'
														className='fs-xxs'
														onClick={() => window.open(link.href, '_blank')}
													>
														{link.text}
													</Button>
												))}
											</div>
										</div>
									</div>
								</div>
							</Col>
						)}
					</Row>
				</Container>
			</div>
		</>
	);
};

export default SportsStatisticsPage;
