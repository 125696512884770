// PAGES
import Homepage from '../pages/Homepage';
import FinancesPage from '../pages/FinancesPage';
import SettingsPage from '../pages/SettingsPage';
import LoginPage from '../pages/AccountPage';
// SPORTS BETTING PAGES
import SportsBettingPage from '../pages/SportsBetting/SportsBettingPage';
import SportsStatisticsPage from '../pages/SportsBetting/SportsStatisticsPage';
// ICONS
import { IoHome } from 'react-icons/io5';
import { IoSettingsSharp } from 'react-icons/io5';
import { AiFillHome } from 'react-icons/ai';
import {
	MdAccountCircle,
	MdVerifiedUser,
	MdOutlineSpaceDashboard,
	MdAttachMoney,
	MdOutlineSettings,
	MdOutlineAccountBox,
	MdOutlineVerifiedUser,
} from 'react-icons/md';
import {
	TbLayoutDashboardFilled,
	TbHome,
	TbBuildingBank,
	TbSettings,
	TbUserCircle,
	TbReportMoney,
	TbCashBanknoteFilled,
	TbTrophyFilled,
	TbCreditCardFilled,
	TbSettingsFilled,
	TbUserFilled,
	TbShirtFilled,
	TbBrandDribbbleFilled, // BASKETBALL
	TbShirtSport,
} from 'react-icons/tb';
import { MdSportsBasketball, MdSportsBaseball } from 'react-icons/md';
import { GiAmericanFootballPlayer } from 'react-icons/gi';

const iconSize = '24';
const iconClass = 'sidebar-icon fs-sm';

const routesConfig = [
	{
		id: 0,
		path: '/dashboard',
		component: Homepage,
		title: 'Dashboard',
		icon: <TbLayoutDashboardFilled size={iconSize} className={iconClass} />,
		isActive: true,
		isProtected: false,
	},
	// {
	// 	id: 1,
	// 	path: '/bet',
	// 	component: SportsBettingPage,
	// 	title: 'Bet',
	// 	icon: <TbTrophyFilled size={iconSize} className={iconClass} />,
	// 	isActive: true,
	// 	isProtected: false,
	// },
	{
		id: 2,
		path: '/finances',
		component: FinancesPage,
		title: 'Finance',
		icon: <TbCreditCardFilled size={iconSize} className={iconClass} />,
		isActive: true,
		isProtected: false,
	},
];

const sportsBettingRoutesConfig = [
	{
		id: 0,
		path: '/betting',
		component: SportsBettingPage,
		title: 'Bet',
		icon: <TbTrophyFilled size={iconSize} className={iconClass} />,
		isActive: true,
		isProtected: false,
	},
	{
		id: 1,
		path: '/statistics',
		component: SportsStatisticsPage,
		title: 'Stats',
		icon: <TbShirtFilled size={iconSize} className={iconClass} />,
		isActive: true,
		isProtected: false,
	},
];

const managementRoutesConfig = [
	{
		id: 0,
		path: '/settings',
		component: SettingsPage,
		title: 'Settings',
		icon: <TbSettingsFilled size={iconSize} className={iconClass} />,
		isActive: true,
		isProtected: false,
	},
	{
		id: 1,
		path: '/account',
		component: LoginPage,
		title: 'Account',
		icon: <TbUserFilled size={iconSize} className={iconClass} />,
		isActive: true,
		isProtected: false,
	},
];

export { routesConfig, sportsBettingRoutesConfig, managementRoutesConfig };
