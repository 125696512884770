import { v4 as uuidv4 } from 'uuid';

// CACHE DATA INTO SESSION STORAGE AS AN ARRAY
const cacheDataInSession = (key, data, dataSourceName) => {
	try {
		// Fetch existing data or initialize an empty array if none exists
		let existingData = sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : [];

		// Ensure that a valid dataSourceName is provided, defaulting to 'unknown' if not passed
		const validDataSource = dataSourceName || 'unknown';

		// Generate a unique dataSourceId in the format `dataSourceName-uuid`
		const dataSourceId = `${validDataSource}-${uuidv4()}`;

		// Check for a duplicate based on unique pick properties and dataSourceName
		const isDuplicate = existingData.some(
			(item) =>
				item.date === data.date &&
				item.team1.name === data.team1.name &&
				item.team2.name === data.team2.name &&
				item.dataSource === validDataSource
		);

		// If no duplicate, add the new item to the array
		if (!isDuplicate) {
			data.dataSourceId = dataSourceId;
			data.dataSource = validDataSource;
			existingData.push(data);
			sessionStorage.setItem(key, JSON.stringify(existingData)); // Update session storage
			console.log('New data added to session storage.');
		} else {
			console.log('Duplicate data found, not added.');
		}
	} catch (error) {
		console.error('Error caching data in session storage:', error);
	}
};

// GET ALL CACHED DATA FROM SESSION
const getAllCachedData = () => {
	try {
		const allData = sessionStorage.getItem('picks');
		return allData ? JSON.parse(allData) : [];
	} catch (error) {
		console.error('Error retrieving all data from session storage:', error);
		return [];
	}
};

// REMOVE A SPECIFIC ITEM FROM SESSION BY MATCHING dataSourceId
const removeCachedData = (key, dataSourceId) => {
	try {
		const jsonData = sessionStorage.getItem(key);
		if (jsonData) {
			let allData = JSON.parse(jsonData);
			// Filter out the item with the matching dataSourceId
			allData = allData.filter((item) => item.dataSourceId !== dataSourceId);
			sessionStorage.setItem(key, JSON.stringify(allData));
			console.log(`Data with dataSourceId ${dataSourceId} removed from session storage.`);
		}
	} catch (error) {
		console.error('Error removing data from session storage:', error);
	}
};

export { cacheDataInSession, getAllCachedData, removeCachedData };
