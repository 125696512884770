import moment from 'moment';
import stringSimilarity from 'string-similarity';

/*
ESPN's hidden API endpoints
https://gist.github.com/akeaswaran/b48b02f1c94f873c6655e7129910fc3b
*/

const espnAPI = {
	football: {
		collegeFootball: {
			// LATEST NEWS FOR COLLEGE FOOTBALL
			latestNews: 'https://site.api.espn.com/apis/site/v2/sports/football/college-football/news',

			// LATEST SCORES FOR COLLEGE FOOTBALL WITH OPTIONAL QUERY PARAMS:
			// 'calendar' SET TO 'blacklist' AND 'dates' AS 'YYYYMMDD' FOR A SPECIFIC DAY
			latestScores: 'https://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard',

			// GAME INFORMATION FOR A SPECIFIC GAME
			// PARAMS: 'gameId' AS THE IDENTIFIER OF THE GAME (EX: 400934572)
			gameInfo: 'https://site.api.espn.com/apis/site/v2/sports/football/college-football/summary?event=:gameId',

			// TEAM INFORMATION BASED ON TEAM ABBREVIATION
			// PARAMS: 'team' (EX: 'all' FOR ALLEGHENY, 'gt' FOR GEORGIA TECH)
			teamInfo: 'https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/:team',

			// RANKINGS FOR COLLEGE FOOTBALL TEAMS
			rankings: 'https://site.api.espn.com/apis/site/v2/sports/football/college-football/rankings',
		},
		nfl: {
			// LATEST NFL SCORES
			scores: 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard',

			// LATEST NFL NEWS
			news: 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/news',

			// LIST OF ALL NFL TEAMS
			allTeams: 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams',

			// INFORMATION ABOUT A SPECIFIC NFL TEAM BASED ON TEAM ABBREVIATION
			specificTeam: 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams/:team',
		},
	},
	baseball: {
		mlb: {
			// LATEST MLB SCORES
			scores: 'https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard',

			// LATEST MLB NEWS
			news: 'https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/news',

			// LIST OF ALL MLB TEAMS
			allTeams: 'https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/teams',

			// INFORMATION ABOUT A SPECIFIC MLB TEAM BASED ON TEAM ABBREVIATION
			specificTeam: 'https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/teams/:team',
		},
		collegeBaseball: {
			// LATEST SCORES FOR COLLEGE BASEBALL
			scores: 'https://site.api.espn.com/apis/site/v2/sports/baseball/college-baseball/scoreboard',
		},
	},
	hockey: {
		nhl: {
			// LATEST NHL SCORES
			scores: 'https://site.api.espn.com/apis/site/v2/sports/hockey/nhl/scoreboard',

			// LATEST NHL NEWS
			news: 'https://site.api.espn.com/apis/site/v2/sports/hockey/nhl/news',

			// LIST OF ALL NHL TEAMS
			allTeams: 'https://site.api.espn.com/apis/site/v2/sports/hockey/nhl/teams',

			// INFORMATION ABOUT A SPECIFIC NHL TEAM BASED ON TEAM ABBREVIATION
			specificTeam: 'https://site.api.espn.com/apis/site/v2/sports/hockey/nhl/teams/:team',
		},
	},
	basketball: {
		nba: {
			// LATEST NBA SCORES
			scores: 'https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard',

			// LATEST NBA NEWS
			news: 'https://site.api.espn.com/apis/site/v2/sports/basketball/nba/news',

			// LIST OF ALL NBA TEAMS
			allTeams: 'https://site.api.espn.com/apis/site/v2/sports/basketball/nba/teams',

			// INFORMATION ABOUT A SPECIFIC NBA TEAM BASED ON TEAM ABBREVIATION
			specificTeam: 'https://site.api.espn.com/apis/site/v2/sports/basketball/nba/teams/:team',
		},
		wnba: {
			// LATEST WNBA SCORES
			scores: 'https://site.api.espn.com/apis/site/v2/sports/basketball/wnba/scoreboard',

			// LATEST WNBA NEWS
			news: 'https://site.api.espn.com/apis/site/v2/sports/basketball/wnba/news',

			// LIST OF ALL WNBA TEAMS
			allTeams: 'https://site.api.espn.com/apis/site/v2/sports/basketball/wnba/teams',

			// INFORMATION ABOUT A SPECIFIC WNBA TEAM BASED ON TEAM ABBREVIATION
			specificTeam: 'https://site.api.espn.com/apis/site/v2/sports/basketball/wnba/teams/:team',
		},
		womensCollegeBasketball: {
			// LATEST SCORES FOR WOMEN'S COLLEGE BASKETBALL
			scores: 'https://site.api.espn.com/apis/site/v2/sports/basketball/womens-college-basketball/scoreboard',

			// LATEST NEWS FOR WOMEN'S COLLEGE BASKETBALL
			news: 'https://site.api.espn.com/apis/site/v2/sports/basketball/womens-college-basketball/news',

			// LIST OF ALL WOMEN'S COLLEGE BASKETBALL TEAMS
			allTeams: 'https://site.api.espn.com/apis/site/v2/sports/basketball/womens-college-basketball/teams',

			// INFORMATION ABOUT A SPECIFIC WOMEN'S COLLEGE BASKETBALL TEAM
			specificTeam: 'https://site.api.espn.com/apis/site/v2/sports/basketball/womens-college-basketball/teams/:team',
		},
		mensCollegeBasketball: {
			// LATEST SCORES FOR MEN'S COLLEGE BASKETBALL
			scores: 'https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/scoreboard',

			// LATEST NEWS FOR MEN'S COLLEGE BASKETBALL
			news: 'https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/news',

			// LIST OF ALL MEN'S COLLEGE BASKETBALL TEAMS
			allTeams: 'https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams',

			// INFORMATION ABOUT A SPECIFIC MEN'S COLLEGE BASKETBALL TEAM
			specificTeam: 'https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/:team',
		},
	},
	soccer: {
		// LATEST SCORES FOR A SPECIFIC SOCCER LEAGUE (PARAMS: 'league')
		scores: 'https://site.api.espn.com/apis/site/v2/sports/soccer/:league/scoreboard',

		// LATEST NEWS FOR A SPECIFIC SOCCER LEAGUE (PARAMS: 'league')
		latestNews: 'https://site.api.espn.com/apis/site/v2/sports/soccer/:league/news',

		// LIST OF TEAMS FOR A SPECIFIC SOCCER LEAGUE (PARAMS: 'league')
		teamInfo: 'https://site.api.espn.com/apis/site/v2/sports/soccer/:league/teams',
	},
};

const getNFLCurrentEvents = async () => {
	const EVENT_API = espnAPI.football.nfl.scores;
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const nextWeek = new Date();
	nextWeek.setDate(today.getDate() + 7);
	nextWeek.setHours(23, 59, 59, 999);

	try {
		const response = await fetch(EVENT_API);
		const data = await response.json();

		const events = data.events;

		const filteredEvents = events.filter((event) => {
			const eventDate = new Date(event.date);
			eventDate.setHours(0, 0, 0, 0);
			return eventDate >= today && eventDate <= nextWeek;
		});

		const categorizedEvents = {
			live: [],
			upcoming: [],
		};

		filteredEvents.forEach((event) => {
			// CONVERT event.date TO CDT AND FORMAT IT
			const eventDate = new Date(event.date);
			const eventInCentralTime = eventDate.toLocaleString('en-US', {
				timeZone: 'America/Chicago',
				weekday: 'short',
				month: 'short',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
				// timeZoneName: 'short',
			});

			// MODIFY shortDetail FIELD FOR NON-LIVE GAMES
			if (event.status.type.completed !== true && event.status.type.name === 'STATUS_IN_PROGRESS') {
				categorizedEvents.live.push(event);
			} else {
				// UPDATE shortDetail FIELD TO SHOW TIME IN CDT FOR UPCOMING GAMES
				event.status.type.shortDetail = eventInCentralTime;
				categorizedEvents.upcoming.push(event);
			}
		});

		return categorizedEvents;
	} catch (error) {
		console.error('Error fetching NFL events:', error);
		return { live: [], upcoming: [] };
	}
};

const getNFLEventByTeamAbbreviation = async (teamAbbreviation) => {
	const EVENT_API = espnAPI.football.nfl.scores;

	try {
		const response = await fetch(EVENT_API);
		const data = await response.json();

		const events = data.events;

		// Extract all team abbreviations from the events
		const allTeamAbbreviations = events.flatMap((event) =>
			event.competitions.flatMap((competition) => competition.competitors.map((competitor) => competitor.team.abbreviation))
		);

		// Find the best match using the string-similarity package
		const bestMatch = stringSimilarity.findBestMatch(teamAbbreviation, allTeamAbbreviations).bestMatch.target;

		// Find the event associated with the best matching team abbreviation
		const bestMatchEvent = events.find((event) =>
			event.competitions.some((competition) =>
				competition.competitors.some((competitor) => competitor.team.abbreviation === bestMatch)
			)
		);

		// Return the event details along with status, isLive, and isComplete
		if (bestMatchEvent) {
			return {
				...bestMatchEvent,
				eventStatus: bestMatchEvent.status?.type?.name || 'Unknown',
				eventStatusDetails: bestMatchEvent.status?.type?.shortDetail || 'No details available',
				isLive: bestMatchEvent.status?.type?.name !== 'STATUS_FINAL' && bestMatchEvent.status?.type?.name !== 'STATUS_SCHEDULED', // True only if not final and not scheduled
				isComplete: bestMatchEvent.status?.type?.completed || false, // True if the event has ended
				bestMatchTeam: bestMatch, // Return the best match team abbreviation
			};
		}

		return null; // Return null if no event found
	} catch (error) {
		console.error('Error fetching NFL events:', error);
		return null;
	}
};

const getCFBEvents = async () => {
	const api = espnAPI.football.collegeFootball.latestScores;

	try {
		const response = await fetch(api);
		const data = await response.json();

		const cfbEvents = data.events.map((event) => {
			const { id, uid, date, status, competitions, links, name, shortName } = event;

			const eventStatus = {
				state: status.type.state,
				completed: status.type.completed,
			};

			const eventLinks = links.map((link) => ({
				text: link.text,
				href: link.href,
			}));

			const competitors = competitions[0].competitors.map((team) => {
				const records = team.records.reduce((acc, record) => {
					acc[record.name.toLowerCase()] = record.summary;
					return acc;
				}, {});

				return {
					name: team.team.shortDisplayName,
					abbreviation: team.team.abbreviation,
					logo: team.team.logo,
					score: team.score,
					homeAway: team.homeAway,
					records: {
						overall: records.overall || 'N/A',
						home: records.home || 'N/A',
						away: records.away || 'N/A',
						conference: records['vs. conf.'] || 'N/A',
					},
				};
			});

			return {
				id,
				uid,
				date,
				name,
				shortName,
				status: eventStatus,
				links: eventLinks,
				competitors,
			};
		});

		return cfbEvents;
	} catch (error) {
		console.error('Error fetching CFB events:', error);
	}
};

// const getCFBEventById = async (id) => {
// 	// const api = espnAPI.football.collegeFootball.gameInfo;
// 	const api = `http://site.api.espn.com/apis/site/v2/sports/football/college-football/summary?event=${id}`;
// }

const getCFBEventIdByTeam = async (teamIdentifier) => {
	const api = espnAPI.football.collegeFootball.latestScores;

	try {
		const response = await fetch(api);
		const data = await response.json();

		for (const event of data.events) {
			for (const competitor of event.competitions[0].competitors) {
				const team = competitor.team;

				if (
					team.name.toLowerCase() === teamIdentifier.toLowerCase() ||
					team.abbreviation.toLowerCase() === teamIdentifier.toLowerCase()
				) {
					return event.id;
				}
			}
		}
		return null;
	} catch (error) {
		console.error('Error fetching events:', error);
		return null;
	}
};

const getNFLEvents = async () => {
	const api = espnAPI.football.nfl.scores;

	try {
		const response = await fetch(api);
		const data = await response.json();

		console.log('getNFLEvents() \n', data);

		const nflEvents = data.events.map((event) => {
			const { id, uid, date, status, competitions, links, name, shortName } = event;

			const eventStatus = {
				state: status.type.state,
				completed: status.type.completed,
			};

			const eventLinks = links.map((link) => ({
				text: link.text,
				href: link.href,
			}));

			const competitors = competitions[0].competitors.map((team) => {
				const records = team.records.reduce((acc, record) => {
					acc[record.name.toLowerCase()] = record.summary;
					return acc;
				}, {});

				return {
					id: team.id,
					name: team.team.shortDisplayName,
					location: team.team.location,
					abbreviation: team.team.abbreviation,
					logo: team.team.logo,
					score: team.score,
					homeAway: team.homeAway,
					color: `#${team.team.color}`,
					displayName: team.team.displayName,
					records: {
						overall: records.overall || 'N/A',
						home: records.home || 'N/A',
						away: records.away || 'N/A',
						conference: records['vs. conf.'] || 'N/A',
					},
					links: team.team.links,
				};
			});

			return {
				id,
				uid,
				matchupAbbrId: `${competitors[0].abbreviation.toLowerCase()}-${competitors[1].abbreviation.toLowerCase()}`,
				matchupCityId: `${competitors[0].location.toLowerCase()}-${competitors[1].location.toLowerCase()}`,
				date,
				name,
				shortName,
				// status: eventStatus,
				status: eventStatus,
				links: eventLinks,
				competitors,
			};
		});

		return nflEvents;
	} catch (error) {
		console.error('Error fetching NFL events:', error);
	}
};

const getCFBTeamByName = async (teamIdentifier, dunkelData) => {
	try {
		// FETCH CFB EVENTS FROM ESPN API
		const api = 'https://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard';
		const response = await fetch(api);
		const data = await response.json();
		const events = data.events;

		// FIND THE EVENT MATCHING THE TEAM IDENTIFIER
		const foundEvent = events.find((event) =>
			event.competitions.some((competition) =>
				competition.competitors.some(
					(competitor) =>
						competitor.team.name.toLowerCase() === teamIdentifier.toLowerCase() ||
						competitor.team.abbreviation.toLowerCase() === teamIdentifier.toLowerCase()
				)
			)
		);

		if (!foundEvent) return null; // NO EVENT FOUND

		// MATCH WITH DUNKEL INDEX DATA
		const matchedDunkelTeam = dunkelData.find((dunkelTeam) =>
			foundEvent.competitions.some((competition) =>
				competition.competitors.some(
					(competitor) => competitor.team.name === dunkelTeam.name || competitor.team.abbreviation === dunkelTeam.abbreviation
				)
			)
		);

		// RETURN FOUND MATCH OR NULL IF NOT FOUND
		return matchedDunkelTeam ? { event: foundEvent, team: matchedDunkelTeam } : null;
	} catch (error) {
		console.error('Error matching teams with Dunkel Index:', error);
		return null;
	}
};

export { getNFLCurrentEvents, getNFLEventByTeamAbbreviation, getCFBEvents, getCFBEventIdByTeam, getNFLEvents, getCFBTeamByName };
