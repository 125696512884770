// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';
import LoadingOverlay from '../../../components/LoadingOverlay';
// HELPERS & SERVICES

const Covers = (props) => {
	const { data, dataLayout, dataViewLess, logos, links } = props;
	const [isLoading, setIsLoading] = useState(false);

	// useEffect(() => {
	// 	if (data) {
	// 		console.log(`COVERS DATA \n`, data);
	// 	}
	// }, []);

	return (
		<>
			{!data || (isLoading && <LoadingOverlay />)}

			{data &&
				data.map((pick, index) => (
					<Col key={`covers-pick-${index}`} xs={12} sm={12} md={12} lg={4} className='mb-3'>
						<div
							className='card--2 rounded p-2 fs-xxs no-scrollbar card-scroll-shadow position-relative'
							style={{ overflowY: 'scroll', maxHeight: '400px' }}
						>
							<div className='card-content covers'>
								<div className='d-flex align-items-center ug-3'>
									<div className='d-flex flex-column align-items-center ug-1 mb-2'>
										{pick.team1Image && <img src={pick.team1Image} className='team-image user-select-none' />}
										{pick.team1Name && <span className='fs-xxxs fw-500 text-muted'>{pick.team1Name}</span>}
									</div>
									<div className='d-flex flex-column align-items-center ug-1 mb-1'>
										{pick.team2Image && <img src={pick.team2Image} className='team-image user-select-none' />}
										{pick.team2Name && <span className='fs-xxxs fw-500 text-muted'>{pick.team2Name}</span>}
									</div>
								</div>
								<img src={logos.covers} className='content-source position-absolute bg-dark p-1 px-2 round' />
								{pick.pickType && (
									<p className='fs-xxx fw-600 mb-2'>
										{pick.pickType}{' '}
										<span className='badge fs-xxs fw-500 py-1 rounded-pill bg-secondary text-light'>
											{pick.pickValueText}
										</span>
									</p>
								)}
								{!dataViewLess && pick.pickDescription && (
									<>
										<p className='fs-xxx fw-400 mb-2'>{pick.pickDescription}</p>

										{pick.pickAnalysis && (
											<a
												href={pick.pickAnalysis}
												target='_blank'
												rel='noopener noreferrer'
												className='fs-xxx fw-600 text-primary'
											>
												Read full analysis
											</a>
										)}
									</>
								)}
							</div>
						</div>
					</Col>
				))}
		</>
	);
};

export default Covers;
