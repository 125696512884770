import axios from 'axios';
import db from './db';

// FETCH EVENTS BY LEAGUE, FILTER BY STATUS, SORT BY WEEK DESCENDING AND DATE ASCENDING
export const getEventsByLeague = async (league) => {
	try {
		// FETCH EVENTS ONLY FOR SPECIFIED LEAGUE
		const events = await db.events.where('league').equals(league.toLowerCase()).toArray();

		// GET CURRENT STATUS FILTER
		const filterStatus = await getCurrentFilter();

		// FILTER BY STATUS IF FILTER IS SET
		const filteredEvents = filterStatus ? events.filter((event) => event.status?.type?.state === filterStatus) : events;

		// SORT BY MOST RECENT WEEK FIRST AND THEN BY DATE (EARLIEST TO LATEST) WITHIN THE SAME WEEK
		return filteredEvents.sort((a, b) => {
			if (a.week !== b.week) {
				return b.week - a.week; // SORT BY WEEK DESCENDING
			}
			return new Date(a.date) - new Date(b.date); // SORT BY DATE ASCENDING
		});
	} catch (error) {
		console.error(`Error fetching events for league ${league}:`, error);
		return [];
	}
};

// FETCH PICKS ASSOCIATED WITH A SPECIFIC EVENT
export const getPicksForEvent = async (eventId) => {
	try {
		return await db.picks.where('eventId').equals(eventId).toArray();
	} catch (error) {
		console.error(`Error fetching picks for event ID ${eventId}:`, error);
		return [];
	}
};

export const updateEventStatusesByTeam = async (league) => {
	try {
		const events = await db.events.where('league').equals(league.toLowerCase()).toArray();
		if (!events || events.length === 0) {
			console.warn(`No events found for league ${league}.`);
			return [];
		}

		const updatedEvents = await Promise.all(
			events.map(async (event) => {
				try {
					const homeTeamId = event.competitions?.homeTeam?.id;
					if (!homeTeamId) {
						console.warn(`Home team ID not found for event ID ${event.id}.`);
						return event;
					}

					const teamUrl = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/${homeTeamId}`;
					const teamResponse = await axios.get(teamUrl);
					const teamData = teamResponse.data;

					const nextEventStatus = teamData.team?.nextEvent?.[0]?.competitions?.[0]?.status;
					if (nextEventStatus) {
						const updatedEvent = {
							...event,
							status: nextEventStatus,
						};
						await db.events.put(updatedEvent);
						console.log(
							`UPDATED EVENT STATUS | ${event.competitions.awayTeam.nickname.toUpperCase()} @ ${event.competitions.homeTeam.nickname.toUpperCase()} | ${
								event.id
							}.`
						);
						return updatedEvent;
					} else {
						console.warn(`Status not found for event ID ${event.id}.`);
						return event;
					}
				} catch (error) {
					console.error(`Error updating status for event ID ${event.id}:`, error);
					return event;
				}
			})
		);

		console.log(`Event statuses updated for league ${league}.`);
		return updatedEvents;
	} catch (error) {
		console.error(`Error updating event statuses for league ${league}:`, error);
		return [];
	}
};

// TOGGLE AN EVENT AS A FAVORITE IN THE DATABASE
export const toggleFavoriteEvent = async (eventId) => {
	try {
		const existingFavorite = await db.favorites.where('eventId').equals(eventId).first();
		if (existingFavorite) {
			await db.favorites.delete(existingFavorite.id); // REMOVE IF EXISTS
		} else {
			await db.favorites.add({ eventId }); // ADD IF NOT EXISTS
		}
	} catch (error) {
		console.error(`Error toggling favorite for event ID ${eventId}:`, error);
	}
};

// GET LAST FETCH TIME FOR A SPECIFIC LEAGUE
export const getLastFetchTime = async (leagueKey) => {
	try {
		const metadata = await db.metadata.get({ key: 'fetchedTime', league: leagueKey });
		return metadata ? metadata.value : null;
	} catch (error) {
		console.error(`Error fetching last fetch time for ${leagueKey}:`, error);
		return null;
	}
};

// STORE CURRENT TIME AS LAST FETCH TIME FOR A SPECIFIC LEAGUE IN ISO FORMAT
export const setLastFetchTime = async (leagueKey) => {
	try {
		const fetchedTime = new Date().toISOString();
		const metadataEntry = { key: 'fetchedTime', league: leagueKey, value: fetchedTime };

		// Remove any existing entries with the same key and league
		await db.metadata.where({ key: 'fetchedTime', league: leagueKey }).delete();
		await db.metadata.put(metadataEntry);
	} catch (error) {
		console.error(`Error setting last fetch time for ${leagueKey}:`, error);
	}
};

// SET FILTER IN METADATA
export const setFilter = async (filterType) => {
	try {
		// REMOVE EXISTING FILTER ENTRY
		await db.metadata.where('key').equals('filter').delete();
		// ADD NEW FILTER
		await db.metadata.add({ key: 'filter', value: filterType });
	} catch (error) {
		console.error(`Error setting filter: ${error}`);
	}
};

// SET LEAGUE IN METADATA
export const setLeague = async (leagueName) => {
	try {
		// REMOVE EXISTING LEAGUE ENTRY
		await db.metadata.where('key').equals('league').delete();
		// ADD NEW LEAGUE
		await db.metadata.add({ key: 'league', value: leagueName });
	} catch (error) {
		console.error(`Error setting league: ${error}`);
	}
};

// GET CURRENT FILTER
export const getCurrentFilter = async () => {
	try {
		const filterEntry = await db.metadata.get({ key: 'filter' });
		return filterEntry ? filterEntry.value : null;
	} catch (error) {
		console.error(`Error fetching current filter: ${error}`);
		return null;
	}
};

// GET CURRENT LEAGUE
export const getCurrentLeague = async () => {
	try {
		const leagueEntry = await db.metadata.get({ key: 'league' });
		return leagueEntry ? leagueEntry.value : null;
	} catch (error) {
		console.error(`Error fetching current league: ${error}`);
		return null;
	}
};
