// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import db from '../stores/db';
// COMPONENTS
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Row, Col, Card, Button, Modal, Form, ButtonGroup, InputGroup, OverlayTrigger, Popover, Image } from 'react-bootstrap';
import LoadingOverlay from '../components/LoadingOverlay';
import Countdown from '../components/Countdown';
import PlayerSearchInput from '../components/PlayerSearchInput';
import NFLDataTable from '../components/tables/NFLDataTable';
import CFBDataTable from '../components/tables/CFBDataTable';
import CBBDataTable from '../components/tables/CBBDataTable';
// ASSETS
import {
	TbShirtSport,
	MdSportsFootball,
	TbShirtFilled,
	TbBallAmericanFootball,
	TbStar,
	TbStarFilled,
	TbCircle,
	TbCircleFilled,
	TbMinus,
	TbHandClick,
	TbBallBasketball,
} from 'react-icons/tb';
// HELPERS & SERVICES
import { setFilter, setLeague, getCurrentFilter, getCurrentLeague } from '../stores/dbQueries';

const Homepage = (props) => {
	const { appInfo, user } = props;
	const [isLoading, setIsLoading] = useState(false);
	// const [events, setEvents] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [selectedLeague, setSelectedLeague] = useState('NFL');
	const [filters, setFilters] = useState({
		team: '',
		status: '',
		// sortOrder: 'desc',
	});

	useEffect(() => {
		const fetchFiltersAndLeague = async () => {
			const storedLeague = await getCurrentLeague();
			const storedFilter = await getCurrentFilter();

			console.log('Stored League:', storedLeague);
			console.log('Stored Filter:', storedFilter);

			if (storedLeague) setSelectedLeague(storedLeague);
			if (storedFilter) setFilters((prev) => ({ ...prev, status: storedFilter }));
		};

		fetchFiltersAndLeague();
	}, []);

	// FETCH INITIAL FILTER AND LEAGUE FROM INDEXEDDB ON COMPONENT LOAD
	useEffect(() => {
		const fetchFiltersAndLeague = async () => {
			const storedLeague = await getCurrentLeague();
			const storedFilter = await getCurrentFilter();

			if (storedLeague) setSelectedLeague(storedLeague);
			if (storedFilter) setFilters((prev) => ({ ...prev, status: storedFilter }));
		};

		fetchFiltersAndLeague();
	}, []);

	// HANDLE LEAGUE CHANGE
	const handleLeagueChange = async (league) => {
		setSelectedLeague(league);

		await setLeague(league);
	};

	// HANDLE STATUS FILTER CHANGE
	const handleStatusFilter = async (status) => {
		const updatedFilters = { ...filters, status };
		setFilters(updatedFilters);

		await setFilter(status);
	};

	// HANDLE PICK COUNT TOGGLE
	// const handlePickCountToggle = () => {
	// 	const newSortOrder = filters.sortOrder === 'desc' ? 'asc' : 'desc';
	// 	setFilters((prev) => ({ ...prev, sortOrder: newSortOrder }));
	// };

	return (
		<>
			{isLoading && <LoadingOverlay />}

			<div className='dashboard-page'>
				<Form className='mb-4' style={{ maxWidth: '700px' }}>
					<Form.Group controlId='filterTeam' className='col-sm-12 col-xl-6'>
						<InputGroup className='d-flex flex-column flex-wrap'>
							{/* League Selection Buttons */}
							<ButtonGroup className='flex-wrap mb-2' aria-label='League selection'>
								<Button
									variant={selectedLeague === 'CFB' ? 'dark' : 'outline-secondary'}
									onClick={() => handleLeagueChange('CFB')}
									size='sm'
									className={`${selectedLeague === 'CFB' ? 'bg-dark bg-gradient' : ''} fs-sm px-2`}
									// disabled
								>
									CFB
								</Button>
								<Button
									variant={selectedLeague === 'NFL' ? 'dark' : 'outline-secondary'}
									onClick={() => handleLeagueChange('NFL')}
									size='sm'
									className={`${selectedLeague === 'NFL' ? 'bg-dark bg-gradient' : ''} fs-sm px-2`}
								>
									NFL
								</Button>
								<Button
									variant={selectedLeague === 'CBB' ? 'dark' : 'outline-secondary'}
									onClick={() => handleLeagueChange('CBB')}
									size='sm'
									className={`${selectedLeague === 'CBB' ? 'bg-dark bg-gradient' : ''} fs-sm px-2`}
								>
									{/* <TbBallBasketball /> */}
									CBB
								</Button>
							</ButtonGroup>

							<ButtonGroup className='flex-wrap mb-2' aria-label='Status filter'>
								<Button
									variant={filters.status === '' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === '' ? 'bg-dark bg-gradient' : ''}`}
								>
									All
								</Button>
								<Button
									variant={filters.status === 'pre' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('pre')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === 'pre' ? 'bg-dark bg-gradient' : ''}`}
								>
									Upcoming
								</Button>
								<Button
									variant={filters.status === 'in' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('in')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === 'in' ? 'bg-dark bg-gradient' : ''}`}
								>
									In Progress
								</Button>
								<Button
									variant={filters.status === 'post' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('post')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === 'post' ? 'bg-dark bg-gradient' : ''}`}
								>
									Completed
								</Button>
								{/* <Button
									variant='primary'
									onClick={handleDownload}
									size='sm'
									className='fs-sm px-2 bg-gradient'
									disabled={isDownloading}
								>
									Download
								</Button> */}
							</ButtonGroup>

							{/* <ButtonGroup className='flex-wrap mb-2' aria-label='Pick Count Filter'>
								<Button
									variant={filters.sortOrder === 'desc' ? 'dark' : 'outline-secondary'}
									onClick={handlePickCountToggle}
									size='sm'
									className='fs-sm px-2'
								>
									{filters.sortOrder === 'desc' ? 'Most Picks' : 'Least Picks'}
								</Button>
							</ButtonGroup> */}
						</InputGroup>
					</Form.Group>
				</Form>

				{/* NFL */}
				{selectedLeague === 'NFL' && <NFLDataTable filters={filters} />}

				{/* CFB */}
				{selectedLeague === 'CFB' && <CFBDataTable filters={filters} />}

				{/* COLLEGE BASKETBALL */}
				{selectedLeague === 'CBB' && <CBBDataTable filters={filters} />}
			</div>
		</>
	);
};

export default Homepage;
