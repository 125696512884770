import { create } from 'zustand';
import { signUp, signIn, signOut } from '../supabase/supabase';
import { routesConfig, sportsBettingRoutesConfig, managementRoutesConfig } from '../routes/routes';
import { supabase } from '../supabase/supabase';

const useAppStore = create((set) => ({
	appInfo: {
		siteTitle: 'Personal Dashboard',
		routes: routesConfig,
		allRoutes: routesConfig.concat(sportsBettingRoutesConfig).concat(managementRoutesConfig),
		sportsBettingRoutes: sportsBettingRoutesConfig,
		managementRoutes: managementRoutesConfig,
	},
	user: null,
	supabaseClient: supabase,

	setSiteTitle: (title) => {
		set((state) => ({
			appInfo: { ...state.appInfo, siteTitle: title },
		}));
	},
	setUser: (user) => set({ user }),

	// AUTH METHODS
	signUp: async (email, password) => {
		const user = await signUp(email, password);
		set({ user });
		return user;
	},
	signIn: async (email, password) => {
		const user = await signIn(email, password);
		set({ user });
		return user;
	},
	signOut: async () => {
		await signOut();
		set({ user: null });
	},

	// FILE UPLOAD METHOD
	uploadFile: async (file) => {
		const formData = new FormData();
		formData.append('file', file);

		try {
			// Replace with your API endpoint
			const response = await fetch('/api/upload', {
				method: 'POST',
				body: formData,
			});

			if (!response.ok) {
				throw new Error('File upload failed');
			}

			const data = await response.json();
			// Update state or perform any other actions with the response data
			return data;
		} catch (error) {
			console.error('Error uploading file:', error);
			throw error;
		}
	},
}));

export default useAppStore;
