// DEPENDENCIES
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// STYLES - RESET, SASS, CUSTOM
import './assets/styles/custom/reset.css';
import './assets/styles/sass/main.scss';
import './assets/styles/custom/custom.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
