import axios from 'axios';
import db from '../stores/db';
import { v4 as uuidv4 } from 'uuid';
import { setLastFetchTime } from '../stores/dbQueries';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://ogzaniacpicks.com' : 'http://localhost:5000';

export const getCFBData = async () => {
	const dunkelIndexData = await fetchDunkelIndexData();
	const oddstraderData = await fetchOddsTraderData();

	const events = [...dunkelIndexData.events, ...oddstraderData.events];
	const picks = [...dunkelIndexData.picks, ...oddstraderData.picks];

	// Filter unique events by ID
	const uniqueEventIds = new Set();
	const uniqueEvents = events.filter((event) => {
		if (uniqueEventIds.has(event.id)) return false;
		uniqueEventIds.add(event.id);
		return true;
	});

	// Fetch existing event IDs and pick IDs from the database
	const existingEventIds = new Set((await db.events.toArray()).map((event) => event.id));
	const existingPickIds = new Set((await db.picks.toArray()).map((pick) => pick.id));

	// Filter out events and picks that already exist in the database
	const newEvents = uniqueEvents.filter((event) => !existingEventIds.has(event.id));
	const newPicks = picks.filter((pick) => !existingPickIds.has(pick.id));

	// Populate additional data for unique events
	for (const event of newEvents) {
		const competitions = event.competitions || {};
		event.competitions = competitions;

		const awayTeam = competitions.awayTeam || {};
		if (awayTeam.id) {
			const awayTeamRes = await getCFBTeamRecordByTeamId(awayTeam.id);
			event.competitions.awayTeam = {
				...awayTeam,
				record: awayTeamRes?.team?.record || null,
				standingSummary: awayTeamRes?.team?.standingSummary || null,
			};
		} else {
			event.competitions.awayTeam = awayTeam;
		}

		const homeTeam = competitions.homeTeam || {};
		if (homeTeam.id) {
			const homeTeamRes = await getCFBTeamRecordByTeamId(homeTeam.id);
			event.competitions.homeTeam = {
				...homeTeam,
				record: homeTeamRes?.team?.record || null,
				standingSummary: homeTeamRes?.team?.standingSummary || null,
			};
		} else {
			event.competitions.homeTeam = homeTeam;
		}
	}

	// Filter out events or picks without a valid ID
	const validEvents = newEvents.filter((event) => event.id);
	const validPicks = newPicks.filter((pick) => pick.id);

	// Save only new events and picks
	await db.events.bulkPut(validEvents);
	await db.picks.bulkPut(validPicks);
	await setLastFetchTime('cfb');

	return { events: validEvents, picks: validPicks };
};

async function fetchOddsTraderData() {
	const fetchURL = `${baseURL}/api/scrape/cfb/oddstrader/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cCFB | FETCHING ODDSTRADER',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Oddstrader',
				league: 'cfb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Oddstrader Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Oddstrader:', error);
	}

	console.log('ODDSTRADER PICKS', picks);

	return { events, picks };
}

async function fetchDunkelIndexData() {
	const fetchURL = `${baseURL}/api/scrape/cfb/dunkelIndex/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		// console.log('FETCH DUNKEL INDEX \n', response);
		console.log(
			'%cCFB | FETCHING DUNKEL INDEX',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Dunkel Index',
				league: 'cfb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis,
				pickLabel: item.pickLabel || 'Dunkel Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Dunkel Index:', error);
		if (error.response) {
			console.error(`Server responded with status ${error.response.status}: ${error.response.data.error}`);
		} else if (error.request) {
			console.error('No response received from server:', error.request);
		} else {
			console.error('Unexpected error:', error.message);
		}
	}

	return { events, picks };
}

async function getCFBTeamRecordByTeamId(teamId) {
	const url = `https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${teamId}`;

	try {
		const data = await axios.get(url);
		return data.data;
	} catch (error) {
		console.error(`Error fetching team details for team ID ${teamId}:`, error);
		return null;
	}
}

export const fetchBoxScoreByEventId = async (eventId) => {
	const fetchURL = `https://site.api.espn.com/apis/site/v2/sports/football/college-football/summary?event=${eventId}`;
	try {
		const response = await axios.get(fetchURL);

		// const boxscoreModel = {
		// 	id: eventId,
		// }
		return response.data;
	} catch (error) {
		console.error('Error fetching event boxscore:', error);
		return null;
	}
};
