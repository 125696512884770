// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
// ASSETS
import { TbCirclePlus } from 'react-icons/tb';
// COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';
import LoadingOverlay from '../../../components/LoadingOverlay';
// HELPERS & SERVICES
import * as cacheService from '../../../helper/cacheHelper';

const getCountdown = (eventDate) => {
	const now = moment();
	const targetDate = moment(eventDate);
	const duration = moment.duration(targetDate.diff(now));

	// Extracting time values from duration
	const days = Math.floor(duration.asDays());
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	// Building the JSX for countdown
	return (
		<span>
			{days > 0 && <span>{days}d </span>}
			{hours > 0 && <span>{hours}h </span>}
			{minutes > 0 && <span>{minutes}m </span>}
			{seconds > 0 && <span>{seconds}s</span>}
		</span>
	);
};

const Oddstrader = (props) => {
	const { data, dataLayout, logos, links } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [mappedData, setMappedData] = useState([]);
	const [countdowns, setCountdowns] = useState([]);

	useEffect(() => {
		if (data) {
			// console.log('oddstrader render data \n', data);
		}
	}, [data]);

	useEffect(() => {
		if (data && data.length > 0) {
			const upcomingGames = data
				.filter((item) => moment(item.date).isAfter(moment()))
				.sort((a, b) => moment(a.date).diff(moment(b.date)));
			setMappedData(upcomingGames);
		}
	}, [data]);

	useEffect(() => {
		if (data && data.length > 0) {
			// Initialize countdowns for each item
			const initialCountdowns = data.map((item) => getCountdown(item.date));
			setCountdowns(initialCountdowns);

			// Update countdowns every second
			const interval = setInterval(() => {
				const updatedCountdowns = data.map((item) => getCountdown(item.date));
				setCountdowns(updatedCountdowns);
			}, 1000); // Update every second

			return () => clearInterval(interval); // Clean up interval on unmount
		}
	}, [data]);

	const defaultSettings = {
		sortable: true,
		autoHeight: true,
		cellStyle: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
			justifyContent: 'center',
			whiteSpace: 'normal',
			padding: '1px',
		},
	};

	// COLUMN DEFINITIONS FOR AG GRID
	const columnDefs = [
		{
			headerName: 'Source',
			field: 'source',
			sortable: true,
			autoHeight: true,
			rowDrag: true,
			width: 130,
			cellStyle: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'start',
				justifyContent: 'center',
				whiteSpace: 'normal',
				padding: '1px',
				backgroundColor: 'var(--bs-gray-dark)',
			},
			cellRenderer: (params) => (
				<img className='h-20' src={logos.oddstrader} alt='Source logo' style={{ height: '20px' }} />
			),
		},
		{
			headerName: 'Matchup',
			field: 'matchup',
			...defaultSettings,
			cellRenderer: (params) => {
				const rowData = params.data;
				// Call getCountdown to retrieve days, hours, minutes, seconds
				const countdown = getCountdown(rowData.date);

				return (
					<div className='d-flex flex-column align-items-start justify-content-center'>
						{/* Date and Time */}
						<div className='d-flex align-items-center ug-1 mb-1'>
							<div className='w-40'></div>
							<span className='lh-1 fs-xxxs text-muted'>
								{moment(rowData.date).format('ddd, MMM Do h:mm A')}
							</span>
						</div>

						{/* COUNTDOWN TIMER */}
						<div className='d-flex align-items-center ug-1 mb-1'>
							<div className='w-40'></div>
							<span className='lh-1 fs-xxxs text-success fw-500'>{countdown}</span>
						</div>

						{/* Team 1 */}
						<div className='d-flex align-items-center ug-1'>
							<img className='w-40 h-auto' src={rowData.team1.image} alt={rowData.team1.name} />
							<span className='lh-1 fw-600'>{rowData.team1.name}</span>
						</div>

						{/* Team 2 */}
						<div className='d-flex align-items-center ug-1'>
							<img className='w-40 h-auto' src={rowData.team2.image} alt={rowData.team2.name} />
							<span className='lh-1 fw-600'>{rowData.team2.name}</span>
						</div>
					</div>
				);
			},
		},
		{
			headerName: 'AI Prediction',
			field: 'aiPrediction',
			...defaultSettings,
			cellRenderer: (params) => {
				const rowData = params.data;
				const total = rowData.aiPrediction.split('-')[2]?.trim();

				return (
					<div className='d-flex flex-column align-items-start justify-content-center ug-1'>
						<span className='lh-1'>
							{rowData.team1.name} <span className='fs-xxs fw-600'>{rowData.team1.aiPredictionScore}</span>
						</span>

						<span className='lh-1'>
							{rowData.team2.name} <span className='fs-xxs fw-600'>{rowData.team2.aiPredictionScore}</span>
						</span>

						<span className='lh-1 mt-1'>{total}</span>
					</div>
				);
			},
		},
		{
			headerName: 'Consensus Spread',
			field: 'consensusSpread',
			...defaultSettings,
			cellRenderer: (params) => {
				const rowData = params.data;

				return (
					<div className='d-flex flex-column align-items-start justify-content-center ug-1'>
						<span className='lh-1'>
							{rowData.team1.name} <span className='fs-xxs fw-600'>{rowData.team1.consensusSpread}</span>
						</span>

						<span className='lh-1'>
							{rowData.team2.name} <span className='fs-xxs fw-600'>{rowData.team2.consensusSpread}</span>
						</span>
					</div>
				);
			},
		},
		{
			headerName: 'Consensus Total',
			field: 'consensusTotal',
			...defaultSettings,
			cellRenderer: (params) => {
				const rowData = params.data;

				return (
					<div className='d-flex flex-column align-items-start justify-content-center ug-1'>
						<span className='lh-1'>
							{rowData.team1.name} <span className='fs-xxs fw-600'>{rowData.team1.consensusTotal}</span>
						</span>

						<span className='lh-1'>
							{rowData.team2.name} <span className='fs-xxs fw-600'>{rowData.team2.consensusTotal}</span>
						</span>
					</div>
				);
			},
		},
	];

	const handleCacheDataInSession = (pick, dataSourceName) => {
		// Ensure dataSourceName is passed correctly and pick contains valid data
		const pickWithDataSource = { ...pick, dataSource: dataSourceName };
		cacheService.cacheDataInSession('picks', pickWithDataSource, dataSourceName);
	};

	return (
		<>
			{!data || (isLoading && <LoadingOverlay />)}

			{dataLayout === 'table' && (
				<div className='ag-theme-alpine' style={{ height: 400, width: '100%' }}>
					<AgGridReact
						columnDefs={columnDefs}
						rowData={mappedData}
						domLayout='autoHeight'
						rowDragManaged={true}
					/>
				</div>
			)}

			{dataLayout === 'card' &&
				data.map((pick, index) => (
					<Col key={`oddstrader-pick-${index}`} xs={12} sm={12} md={12} lg={4} className='mb-3'>
						<div
							className='card--2 rounded p-2 fs-xxs no-scrollbar card-scroll-shadow position-relative'
							style={{ overflow: 'visible', maxHeight: '400px' }}
						>
							<div
								className='position-absolute add-to-cache'
								onClick={() => handleCacheDataInSession(pick, 'oddstrader')}
								style={{
									top: '-18px',
									left: '-18px',
									zIndex: 2000,
									cursor: 'pointer',
									filter: 'drop-shadow(#1c89566e 0px 1px 2px)',
								}}
							>
								<TbCirclePlus strokeWidth={1.4} fill='#f6fdfa' color='#198754' size='45px' />
							</div>

							<div className='card-content oddstrader'>
								<div className='d-flex align-items-center ug-2 mb-2'>
									{/* TEAM 1 */}
									<div className='d-flex flex-column align-items-center'>
										{pick.team1.image && (
											<img
												src={pick.team1.image}
												className='team-image user-select-none h-40'
												alt={pick.team1.name}
											/>
										)}
										{pick.team1.name && (
											<p className='fs-xxxs fw-500 text-muted'>
												{pick.team1.name}{' '}
												{pick.team1.rank && (
													<span className='fs-xxxxs fw-500 text-muted'>{pick.team1.rank}</span>
												)}{' '}
												{pick.team1.record && (
													<span className='fs-xxxs fw-500 text-muted'>{pick.team1.record}</span>
												)}
											</p>
										)}
									</div>
									{/* TEAM 2 */}
									<div className='d-flex flex-column align-items-center'>
										{pick.team2.image && (
											<img
												src={pick.team2.image}
												className='team-image user-select-none h-40'
												alt={pick.team2.name}
											/>
										)}
										{pick.team1.name && (
											<p className='fs-xxxs fw-500 text-muted'>
												{pick.team2.name}{' '}
												{pick.team2.rank && (
													<span className='fs-xxxxs fw-500 text-muted'>{pick.team2.rank}</span>
												)}{' '}
												{pick.team2.record && (
													<span className='fs-xxxs fw-500 text-muted'>{pick.team2.record}</span>
												)}
											</p>
										)}
									</div>
								</div>

								<img
									src={logos.oddstrader}
									className='content-source position-absolute bg-dark p-1 px-2 round'
								/>

								{pick.aiPrediction && <p className='fs-xxx fw-600 mb-2'>{pick.aiPrediction}</p>}

								{pick.consensusSpread && (
									<>
										<span>Consensus Spread</span>
										<p className='fs-xxx fw-400 mb-2'>
											{pick.consensusSpread.split('-').map((teamSpread, index) => (
												<span
													key={`spread-${index}`}
													className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content me-1'
												>
													{teamSpread.trim()}
												</span>
											))}
										</p>
									</>
								)}

								{pick.consensusTotal && (
									<>
										<span>Consensus Total</span>
										<p className='fs-xxx fw-400 mb-2'>
											{pick.consensusTotal.split('-').map((teamTotal, index) => (
												<span
													key={`total-${index}`}
													className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content me-1'
												>
													{teamTotal.trim()}
												</span>
											))}
										</p>
									</>
								)}
								<div className='d-flex align-items-center flex-wrap ug-1'>
									{pick.team1.powerRanking && pick.team1.powerRanking !== '-' && (
										<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
											{pick.team1.name} Power Ranking {pick.team1.powerRanking}
										</span>
									)}
									{pick.team2.powerRanking && pick.team2.powerRanking !== '-' && (
										<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
											{pick.team2.name} Power Ranking {pick.team2.powerRanking}
										</span>
									)}
								</div>
							</div>
						</div>
					</Col>
				))}
		</>
	);
};

export default Oddstrader;
