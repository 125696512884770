import Dexie from 'dexie';

const db = new Dexie('SportsDatabase');

db.version(1).stores({
	nflRoster: 'id, fullName, position, headshotURL, team, teamImageURL, athleteGameLogLink',
	events: 'id, date, league, competitions, status',
	picks:
		'id, eventId, source, league, pickValue, teamId, analysis, pickLabel, pickSourceURL, pickTeamLogo, matchedTeam, matchedTeamNextEvent, meta',
	favorites: '++id, eventId',
	metadata: '++id, key, league, value',
});

// Example Metadata Keys:
// - Fetched time: `{ key: "fetchedTime", league: "nfl", value: <timestamp> }`
// - Filters: `{ key: "filters", league: "nfl", value: { eventStatus: "upcoming", league: "NFL" } }`

export default db;
