import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// ICONS
import {
	TbLayoutDashboardFilled,
	TbCreditCardFilled,
	TbTrophyFilled,
	TbShirtFilled,
	TbSettingsFilled,
	TbUserFilled,
	TbSquareRotatedFilled,
	TbApi,
	TbRefresh,
} from 'react-icons/tb';

// MENU ITEMS
const menuItems = [
	{
		id: 0,
		path: '/dashboard',
		title: 'Dashboard',
		icon: <TbLayoutDashboardFilled className='navbar-item-icon' />,
		isActive: true,
		className: 'main-link',
	},
	{
		id: 1,
		path: '/finances',
		title: 'Finance',
		icon: <TbCreditCardFilled className='navbar-item-icon' />,
		isActive: false,
		className: 'finance-link',
	},
	// {
	// 	id: 2,
	// 	path: '/betting',
	// 	title: 'Bet',
	// 	icon: <TbTrophyFilled />,
	// 	isActive: true,
	// 	className: 'betting-link',
	// },
	// {
	// 	id: 3,
	// 	path: '/statistics',
	// 	title: 'Stats',
	// 	icon: <TbShirtFilled />,
	// 	isActive: true,
	// 	className: 'stats-link',
	// },
	{
		id: 4,
		path: '/settings',
		title: 'Settings',
		icon: <TbSettingsFilled className='navbar-item-icon' />,
		isActive: true,
		className: 'settings-link',
	},
	{
		id: 5,
		path: '/account',
		title: 'Account',
		icon: <TbUserFilled className='navbar-item-icon' />,
		isActive: false,
		className: 'account-link',
	},
];

const isActiveRoute = (itemPath, currentPath) => {
	return itemPath === currentPath ? 'active' : '';
};

const NavBar = () => {
	const location = useLocation();
	const [apiStatus, setApiStatus] = useState('Checking...');
	const [apiURL] = useState(process.env.NODE_ENV === 'production' ? 'https://ogzaniacpicks.com' : 'http://localhost:5000');

	// useEffect(() => {
	// 	checkApiStatus();
	// }, []);

	// const checkApiStatus = () => {
	// 	setApiStatus('Checking...');
	// 	const xhr = new XMLHttpRequest();
	// 	const fullApiUrl = `${apiURL}/api/scrape/nfl/oddstrader/picks`;
	// 	xhr.open('GET', fullApiUrl, true);
	// 	xhr.onreadystatechange = function () {
	// 		if (xhr.readyState === 4) {
	// 			if (xhr.status === 200) {
	// 				setApiStatus('live');
	// 			} else {
	// 				setApiStatus('not live');
	// 			}
	// 		}
	// 	};
	// 	xhr.onerror = function () {
	// 		setApiStatus('not live');
	// 	};
	// 	xhr.send();
	// };

	return (
		<div className='floating-navbar bg-dark bg-gradient d-flex justify-content-around align-items-center py-2 ug-3'>
			{/* MAIN MENU ITEMS */}
			{menuItems.map(
				(item) =>
					item.isActive && (
						<NavLink
							key={item.id}
							to={item.path}
							className={`link text-light fw-400 text-uppercase fit-content ${item.className} ${isActiveRoute(
								item.path,
								location.pathname
							)}`}
						>
							<div className='navbar-item-wrapper d-flex flex-column align-items-center'>
								{item.icon}
								<p className='navbar-item-label fs-xxs lh-normal fw-600 mt-1 opacity-50'>{item.title}</p>
							</div>
						</NavLink>
					)
			)}
		</div>

		// <div
		// 	className='navbar bg-dark bg-gradient d-flex justify-content-around align-items-center py-2'
		// 	style={{
		// 		position: 'fixed',
		// 		zIndex: 10000,
		// 		bottom: '0px',
		// 		left: '0px',
		// 		width: '100%',
		// 	}}
		// >
		// 	<div className='d-flex flex-column' style={{ width: '90%' }}>
		// 		{/* MAIN MENU ITEMS */}
		// 		<div className='d-flex justify-content-around align-items-center'>
		// 			{menuItems.map(
		// 				(item) =>
		// 					item.isActive && (
		// 						<NavLink
		// 							key={item.id}
		// 							to={item.path}
		// 							className={`link text-light fw-400 text-uppercase fit-content ${item.className} ${isActiveRoute(
		// 								item.path,
		// 								location.pathname
		// 							)}`}
		// 						>
		// 							<div className='item d-flex flex-column align-items-center'>
		// 								{item.icon}
		// 								<p className='label fs-xs lh-normal fw-500 mt-1'>{item.title}</p>
		// 							</div>
		// 						</NavLink>
		// 					)
		// 			)}
		// 		</div>

		// 		{/* API STATUS */}
		// 		<div className='d-flex justify-content-start align-items-center ug-2 mt-3'>
		// 			<div className='d-flex align-items-center' onClick={checkApiStatus} style={{ cursor: 'pointer' }}>
		// 				<TbRefresh size={20} strokeWidth={2} className='text-white' />
		// 			</div>
		// 			<p
		// 				className={`d-flex align-items-center ug-1 lh-normal badge text-uppercase bg-gradient round ${
		// 					apiStatus === 'live' ? 'bg-success' : apiStatus === 'not live' ? 'bg-danger' : 'bg-white text-black'
		// 				}`}
		// 				style={{ padding: '2px 6px' }}
		// 			>
		// 				<span className='lh-normal fs-xxs fw-500'>
		// 					{apiStatus === 'live' ? 'LIVE' : apiStatus === 'not live' ? 'NO CONNECTION' : 'Checking...'}
		// 				</span>
		// 			</p>

		// 			<p className='d-flex align-items-center fs-xs text-light fw-500 ug-1'>
		// 				<TbApi strokeWidth={1.8} size={23} className='text-white' />
		// 				{apiURL}
		// 			</p>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default NavBar;
