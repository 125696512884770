// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// import ReactJson from 'react-json-view';
// COMPONENTS
import {
	Container,
	Row,
	Col,
	Button,
	ButtonGroup,
	Dropdown,
	OverlayTrigger,
	Tooltip,
	Modal,
} from 'react-bootstrap';
import CustomBlock from '../../components/CustomBlock';
import LoadingOverlay from '../../components/LoadingOverlay';
// DATA SOURCE VIEWS
import Oddstrader from './DataSourceViews/Oddstrader';
import Pickswise from './DataSourceViews/Pickswise';
import Covers from './DataSourceViews/Covers';
import DunkelIndex from './DataSourceViews/DunkelIndex';
// ASSETS
import {
	TbX,
	TbApi,
	TbDatabase,
	TbExternalLink,
	TbBraces,
	TbCards,
	TbTableColumn,
	TbEyePlus,
	TbEyeMinus,
	TbCircleMinus,
} from 'react-icons/tb';
// SERVICES & HELPERS
import * as apiTestService from '../../services/apiTest';
import * as scrapeService from '../../services/scrapeService';
import * as cacheService from '../../helper/cacheHelper';

const filterDefault_Layout = 'card'; // 'table' | 'cards'
const filterDefault_ViewLess = false;
const filterDefault_SourceFilters = ['oddstrader', 'covers', 'pickswise', 'dunkelIndex'];

// DATA SOURCE COMPONENT
const DataSource = ({ mainLogo, options, tooltipId, rootLink }) => {
	return (
		<ButtonGroup className='ml-2'>
			<OverlayTrigger
				trigger='click'
				placement='right'
				overlay={
					<Tooltip id={tooltipId} className='custom-tooltip'>
						<ButtonGroup vertical className='custom-button-group fs-xxs'>
							{options.map((option, index) => (
								<ButtonGroup key={index} className='custom-button-group__group fs-xxs'>
									<Button
										variant='outline-dark'
										size='sm'
										onClick={option.onClick}
										className='d-flex align-items-center ug-2 fs-xxs text-white text-uppercase'
									>
										<img src={option.logo} className='w-30' alt={`${option.leagueType} PICKS`} />
										{option.label}
									</Button>
									{option.externalLink && (
										<Button
											variant='outline-dark'
											size='sm'
											onClick={() => window.open(option.externalLink)}
											className='d-flex align-items-center ug-1 fs-xxs text-white text-uppercase'
										>
											<img src={option.externalLinkImage} className='h-15' alt={`${rootLink} external`} />
											<TbExternalLink className='fs-xs' />
										</Button>
									)}
								</ButtonGroup>
							))}
						</ButtonGroup>
					</Tooltip>
				}
				rootClose
			>
				<Button variant='dark' className='d-flex align-items-center ug-1'>
					{mainLogo}
				</Button>
			</OverlayTrigger>
		</ButtonGroup>
	);
};

const OddstraderCard = ({ pick, handleCacheDataInSession, logos }) => {
	return (
		<Col xs={12} sm={12} md={12} lg={4} className='mb-3'>
			<div
				className='card--2 rounded p-2 fs-xxs no-scrollbar card-scroll-shadow position-relative'
				style={{ overflow: 'visible', maxHeight: '400px' }}
			>
				<div
					className='position-absolute add-to-cache'
					onClick={() => handleCacheDataInSession(pick, 'oddstrader')}
					style={{
						top: '-18px',
						left: '-18px',
						zIndex: 2000,
						cursor: 'pointer',
						filter: 'drop-shadow(#1c89566e 0px 1px 2px)',
					}}
				>
					<TbCircleMinus strokeWidth={1.4} fill='#ffe6e6' color='#e63946' size='45px' />
				</div>

				<div className='card-content oddstrader'>
					<div className='d-flex align-items-center ug-2 mb-2'>
						{/* TEAM 1 */}
						<div className='d-flex flex-column align-items-center'>
							{pick.team1.image && (
								<img
									src={pick.team1.image}
									className='team-image user-select-none h-40'
									alt={pick.team1.name}
								/>
							)}
							{pick.team1.name && (
								<p className='fs-xxxs fw-500 text-muted'>
									{pick.team1.name}{' '}
									{pick.team1.rank && <span className='fs-xxxxs fw-500 text-muted'>{pick.team1.rank}</span>}{' '}
									{pick.team1.record && (
										<span className='fs-xxxs fw-500 text-muted'>{pick.team1.record}</span>
									)}
								</p>
							)}
						</div>
						{/* TEAM 2 */}
						<div className='d-flex flex-column align-items-center'>
							{pick.team2.image && (
								<img
									src={pick.team2.image}
									className='team-image user-select-none h-40'
									alt={pick.team2.name}
								/>
							)}
							{pick.team2.name && (
								<p className='fs-xxxs fw-500 text-muted'>
									{pick.team2.name}{' '}
									{pick.team2.rank && <span className='fs-xxxxs fw-500 text-muted'>{pick.team2.rank}</span>}{' '}
									{pick.team2.record && (
										<span className='fs-xxxs fw-500 text-muted'>{pick.team2.record}</span>
									)}
								</p>
							)}
						</div>
					</div>

					<img
						src={logos.oddstrader}
						className='content-source position-absolute bg-dark p-1 px-2 round w-100'
					/>

					{pick.aiPrediction && <p className='fs-xxx fw-600 mb-2'>{pick.aiPrediction}</p>}

					{pick.consensusSpread && (
						<>
							<span>Consensus Spread</span>
							<p className='fs-xxx fw-400 mb-2'>
								{pick.consensusSpread.split('-').map((teamSpread, index) => (
									<span
										key={`spread-${index}`}
										className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content me-1'
									>
										{teamSpread.trim()}
									</span>
								))}
							</p>
						</>
					)}

					{pick.consensusTotal && (
						<>
							<span>Consensus Total</span>
							<p className='fs-xxx fw-400 mb-2'>
								{pick.consensusTotal.split('-').map((teamTotal, index) => (
									<span
										key={`total-${index}`}
										className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content me-1'
									>
										{teamTotal.trim()}
									</span>
								))}
							</p>
						</>
					)}

					<div className='d-flex align-items-center flex-wrap ug-1'>
						{pick.team1.powerRanking && pick.team1.powerRanking !== '-' && (
							<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
								{pick.team1.name} Power Ranking {pick.team1.powerRanking}
							</span>
						)}
						{pick.team2.powerRanking && pick.team2.powerRanking !== '-' && (
							<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
								{pick.team2.name} Power Ranking {pick.team2.powerRanking}
							</span>
						)}
					</div>
				</div>
			</div>
		</Col>
	);
};

const SportsBettingPage = (props) => {
	const { appInfo, user } = props;
	const [isLoading, setIsLoading] = useState(false);
	const buttonIconClass = 'fs-sm';
	// SCRAPED DATA
	const [data, setData] = useState(null);
	const [dataSourceName, setDataSourceName] = useState(null);
	const [dataSourceLeagueType, setDataSourceLeagueType] = useState(null);
	// SCRAPED DATA FILTERS
	const [dataLayout, setDataLayout] = useState(filterDefault_Layout);
	const [filter_ViewLess, setFilter_ViewLess] = useState(filterDefault_ViewLess);
	const [filter_SourceFilters, setFilter_SourceFilters] = useState(filterDefault_SourceFilters);
	// CACHED DATA
	const [showUserPicksModal, setShowUserPicksModal] = useState(false);
	const [cachedPicks, setCachedPicks] = useState(null);
	// LOGOS & LINKS
	const logos = {
		oddstrader: 'https://otcdn.virginia.us-east-1.oddstrader.com/OT-LogoWhiteGreen.svg',
		covers: 'https://img.covers.com/covers/header_v2/header-logo.svg',
		pickswise:
			'https://images.pickswise.com/WztLS2YuOy4uDXu2VAoHeMxHmRcozSe9ai0UZ5vkjnA/resize:fill:260:40:0/gravity:ce/quality:100/aHR0cHM6Ly93d3cucGlja3N3aXNlLmNvbS93cC1jb250ZW50L29mZmxvYWQvdXBsb2Fkcy8yMDIxLzA3LzIzMDc0MTQ1L1BpY2tzd2lzZS0yY29sb3ItbG9nby5wbmc',
		dunkelIndex: 'https://www.dunkelindex.com/resources/front/images/footer-logo.png',
		nfl: 'https://static.www.nfl.com/image/upload/v1554321393/league/nvfr7ogywskqrfaiu38m.svg',
		cfb: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/NCAA_logo.svg',
	};
	const links = {
		oddstrader: {
			home: 'https://oddstrader.com',
			nfl: {
				odds: 'https://oddstrader.com/nfl',
				picks: 'https://oddstrader.com/nfl/picks',
			},
			cfb: {
				odds: 'https://oddstrader.com/ncaa-college-football',
				picks: 'https://oddstrader.com/ncaa-college-football/picks',
			},
		},
		covers: {
			home: 'https://www.covers.com',
			nfl: {
				odds: 'https://www.covers.com/sport/football/nfl/odds',
				picks: 'https://www.covers.com/picks/nfl',
			},
			cfb: {
				odds: 'https://www.covers.com/sport/football/ncaaf/odds',
				picks: 'https://www.covers.com/picks/ncaaf',
			},
		},
		pickswise: {
			home: 'https://www.pickswise.com',
			nfl: {
				picks: 'https://www.pickswise.com/nfl/picks/',
			},
			cfb: {
				picks: 'https://www.pickswise.com/college-football/picks/',
			},
		},
		dunkelIndex: {
			home: 'https://www.dunkelindex.com',
			picks: 'https://www.dunkelindex.com/picks',
			nflPicks: 'https://www.dunkelindex.com/picks/nfl',
			cfbPicks: 'https://www.dunkelindex.com/picks/fbs',
		},
	};

	// useEffect(() => {
	// 	const logBadgeStyles = `
	// 		background: #495057;
	// 		color: #dee2e6;
	// 		padding: 2px 6px;
	// 		border-radius: 4px;
	// 		font-weight: bold;
	// 		font-size: 12px;
	// 	`;

	// 	if (data) {
	// 		const combinedData = `scrape/${dataSourceLeagueType}/${dataSourceName} (${dataLayout})`;
	// 		console.log('%c%s', logBadgeStyles, combinedData, '\n', data);

	// 		// console.log('%cData:', logBadgeStyles, data);
	// 		// console.log(data);
	// 	}
	// }, [data, dataSourceName, dataSourceLeagueType, dataLayout]);

	useEffect(() => {
		if (cachedPicks) {
			console.log('CACHED DATA: \n', [cachedPicks]);
		}
	}, [cachedPicks]);

	const handleClear = () => {
		setData(null);
		setDataSourceName(null);
		setDataSourceLeagueType(null);
		setDataLayout(filterDefault_Layout);
	};
	const toggleSourceFilter = (source) => {
		setFilter_SourceFilters((prevFilters) =>
			prevFilters.includes(source)
				? prevFilters.filter((filter) => filter !== source)
				: [...prevFilters, source]
		);
	};
	const handleGetCachedDataFromSession = () => {
		const savedPicks = cacheService.getAllCachedData();
		console.log('CACHED DATA: \n', savedPicks);

		setCachedPicks(savedPicks);
	};

	// DATA SCRAPING
	const scrapeNFLPicks = async (filters) => {
		handleClear();
		setIsLoading(true);
		try {
			const response = await scrapeService.scrapeNFLPicks(filters);
			setData(response);
			setDataSourceName('nflPicks');
			setDataSourceLeagueType('nfl');
		} catch (error) {
			console.error('Error fetching apiTestData:', error);
		} finally {
			setIsLoading(false);
		}
	};
	const scrapeCFBPicks = async (filters) => {
		handleClear();
		setIsLoading(true);
		try {
			const response = await scrapeService.scrapeCFBPicks(filters);
			setData(response);
			setDataSourceName('cfbPicks');
			setDataSourceLeagueType('cfb');
		} catch (error) {
			console.error('Error fetching CFB picks:', error);
		} finally {
			setIsLoading(false);
		}
	};
	const scrapeOddstrader = async (leagueType, dataType) => {
		handleClear();
		setIsLoading(true);
		try {
			const response = await scrapeService.scrapeOddstrader(leagueType, dataType);
			setData(response);
			setDataSourceName('oddstrader');
			setDataSourceLeagueType(leagueType);
		} catch (error) {
			console.error('Error fetching scraped data:', error);
		} finally {
			setIsLoading(false);
		}
	};
	// const scrapeCovers = async (leagueType, dataType) => {
	// 	handleClear();
	// 	setIsLoading(true);
	// 	try {
	// 		const response = await scrapeService.scrapeCovers(leagueType, dataType);
	// 		setData(response);
	// 		setDataSourceName('covers');
	// 		setDataSourceLeagueType(leagueType);
	// 	} catch (error) {
	// 		// console.error('Error fetching apiTestData:', error);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };
	const scrapeCovers = async (leagueType) => {
		handleClear();
		setIsLoading(true);

		try {
			const [picksResponse, propsResponse] = await Promise.all([
				scrapeService.scrapeCovers(leagueType, 'picks'),
				scrapeService.scrapeCovers(leagueType, 'props'),
			]);

			const combinedData = {
				picks: picksResponse,
				props: propsResponse,
			};

			setData(combinedData);
			setDataSourceName('covers');
			setDataSourceLeagueType(leagueType);
		} catch (error) {
			console.error(`Error fetching picks and props for ${leagueType}:`, error);
		} finally {
			setIsLoading(false);
		}
	};
	const scrapePickswise = async (leagueType, dataType) => {
		handleClear();
		setIsLoading(true);
		try {
			const response = await scrapeService.scrapePickswise(leagueType, dataType);
			setData(response);
			setDataSourceName('pickswise');
			setDataSourceLeagueType(leagueType);
		} catch (error) {
			// console.error('Error fetching apiTestData:', error);
		} finally {
			setIsLoading(false);
		}
	};
	const scrapeDunkelIndex = async (leagueType, dataType) => {
		handleClear();
		setIsLoading(true);
		try {
			const response = await scrapeService.scrapeDunkelIndex(leagueType, dataType);
			setData(response);
			setDataSourceName('dunkelIndex');
			setDataSourceLeagueType(leagueType);
		} catch (error) {
			// console.error('Error fetching apiTestData:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCacheDataInSession = (pick, dataSourceName) => {
		// Ensure the dataSourceName is passed correctly
		const pickWithDataSource = { ...pick, dataSource: dataSourceName };

		// Call the cache service to store the data in session
		cacheService.cacheDataInSession('picks', pickWithDataSource, dataSourceName);

		console.log(`Cached pick with data source: ${dataSourceName}`);
	};

	// DATA RENDERING
	const renderDataValue = (value) => {
		if (value === null) {
			return <span className='text-muted'>null</span>; // HANDLE NULL VALUES
		} else if (Array.isArray(value)) {
			return (
				<ul>
					{value.map((item, index) => (
						<li key={index}>{renderDataValue(item)}</li>
					))}
				</ul>
			);
		} else if (typeof value === 'object') {
			return (
				<div>
					{Object.entries(value).map(([subKey, subValue], index) => (
						<div key={index}>
							<strong>{subKey}:</strong> {renderDataValue(subValue)}
						</div>
					))}
				</div>
			);
		} else {
			return <span>{value !== undefined ? value.toString() : 'undefined'}</span>; // HANDLE PRIMITIVE VALUES
		}
	};

	return (
		<>
			{isLoading && <LoadingOverlay />}

			<div className='sports-betting-page'>
				{/* DATA SOURCES */}
				<div className='d-flex flex-column ug-2'>
					{/* <h2 className='fs-sm text-body-secondary fw-700 mb-1'>Data Sources</h2> */}

					<div className='d-flex flex-wrap justify-content-end ug-1 mt-2'>
						<ButtonGroup>
							<Button
								title='Card View'
								size='sm'
								variant='success'
								// className='d-flex align-items-center ug-1 fs-xs px-2 py-1'
								className='d-flex align-items-center ug-1'
								onClick={() => {
									handleGetCachedDataFromSession();
									setShowUserPicksModal(true);
								}}
							>
								<TbCards strokeWidth={2.2} />
								<span className='fs-xxs lh-normal text-white'>Saved</span>
							</Button>

							{/* <Button
								title='Table View'
								size='sm'
								variant='dark'
								// className='d-flex align-items-center ug-1 fs-xs px-2 py-1'
								className={`d-flex align-items-center ug-1 ${dataLayout !== 'table' && 'opacity-50'}`}
								onClick={() => setDataLayout('table')}
								active={dataLayout === 'table'}
								checked={dataLayout === 'table'}
							>
								<TbTableColumn strokeWidth={2.2} />
								<span className='fs-xxs lh-normal text-white'>TABLE</span>
							</Button> */}
						</ButtonGroup>
					</div>

					{/* DATA SOURCES */}
					<div className='d-flex flex-wrap align-items-center ug-1'>
						{/* NFL/CFB */}
						<DataSource
							mainLogo={
								<>
									<img className='h-20' src={logos.nfl} alt='main logo' />
									<img className='h-20' src={logos.cfb} alt='main logo' />
								</>
							}
							tooltipId='tooltip__oddstrader'
							rootLink={links.oddstrader}
							options={[
								{
									leagueType: 'nfl',
									logo: logos.nfl,
									label: 'NFL PICKS',
									onClick: () => {
										const filters = ['oddstrader', 'covers', 'pickswise', 'dunkelIndex'];
										scrapeNFLPicks(filters);
									},
								},
								{
									leagueType: 'cfb',
									logo: logos.cfb,
									label: 'CFB PICKS',
									onClick: () => {
										const filters = ['oddstrader', 'covers', 'pickswise', 'dunkelIndex'];
										scrapeCFBPicks(filters);
									},
								},
							]}
						/>

						<ButtonGroup>
							{/* ODDS TRADER */}
							<DataSource
								mainLogo={<img className='h-20' src={logos.oddstrader} alt='main logo' />}
								tooltipId='tooltip__oddstrader'
								rootLink={links.oddstrader}
								options={[
									{
										leagueType: 'nfl',
										logo: logos.nfl,
										label: 'NFL PICKS',
										onClick: () => scrapeOddstrader('nfl', 'picks'),
										externalLink: links.oddstrader.nfl.picks,
										externalLinkImage: logos.oddstrader,
									},
									{
										leagueType: 'cfb',
										logo: logos.cfb,
										label: 'CFB PICKS',
										onClick: () => scrapeOddstrader('cfb', 'picks'),
										externalLink: links.oddstrader.cfb.picks,
										externalLinkImage: logos.oddstrader,
									},
								]}
							/>

							{/* COVERS */}
							<DataSource
								mainLogo={<img className='h-20' src={logos.covers} alt='main logo' />}
								tooltipId='tooltip__covers'
								rootLink={links.covers}
								options={[
									{
										leagueType: 'nfl',
										logo: logos.nfl,
										label: 'NFL PICKS',
										onClick: () => scrapeCovers('nfl', 'picks'),
										externalLink: links.covers.nfl.picks,
										externalLinkImage: logos.covers,
									},
									{
										leagueType: 'cfb',
										logo: logos.cfb,
										label: 'CFB PICKS',
										onClick: () => scrapeCovers('cfb', 'picks'),
										externalLink: links.covers.cfb.picks,
										externalLinkImage: logos.covers,
									},
								]}
							/>

							{/* PICKSWISE */}
							<DataSource
								mainLogo={<img className='h-15' src={logos.pickswise} alt='main logo' />}
								tooltipId='tooltip__pickswise'
								rootLink={links.pickswise}
								options={[
									{
										leagueType: 'nfl',
										logo: logos.nfl,
										label: 'NFL PICKS',
										onClick: () => scrapePickswise('nfl', 'picks'),
										externalLink: links.pickswise.nfl.picks,
										externalLinkImage: logos.pickswise,
									},
									{
										leagueType: 'cfb',
										logo: logos.cfb,
										label: 'CFB PICKS',
										onClick: () => scrapePickswise('cfb', 'picks'),
										externalLink: links.pickswise.cfb.picks,
										externalLinkImage: logos.pickswise,
									},
								]}
							/>

							{/* DUNKEL INDEX */}
							<DataSource
								mainLogo={<img className='h-20' src={logos.dunkelIndex} alt='main logo' />}
								tooltipId='tooltip__dunkelIndex'
								rootLink={links.dunkelIndex}
								options={[
									{
										leagueType: 'nfl',
										logo: logos.nfl,
										label: 'NFL PICKS',
										onClick: () => scrapeDunkelIndex('nfl', 'picks'),
										externalLink: links.dunkelIndex.nflPicks,
										externalLinkImage: logos.dunkelIndex,
									},
									{
										leagueType: 'cfb',
										logo: logos.cfb,
										label: 'CFB PICKS',
										onClick: () => scrapeDunkelIndex('cfb', 'picks'),
										externalLink: links.dunkelIndex.cfbPicks,
										externalLinkImage: logos.dunkelIndex,
									},
								]}
							/>
						</ButtonGroup>
					</div>

					{/* DATA ACTIONS */}
					<div className='d-flex flex-wrap justify-content-end ug-1 mt-2'>
						<ButtonGroup>
							<Button
								title='Card View'
								size='sm'
								variant='dark'
								// className='d-flex align-items-center ug-1 fs-xs px-2 py-1'
								className={`d-flex align-items-center ug-1 ${dataLayout !== 'card' && 'opacity-50'}`}
								onClick={() => setDataLayout('card')}
								active={dataLayout === 'card'}
								checked={dataLayout === 'card'}
							>
								<TbCards strokeWidth={2.2} />
								<span className='fs-xxs lh-normal text-white'>CARD</span>
							</Button>

							<Button
								title='Table View'
								size='sm'
								variant='dark'
								// className='d-flex align-items-center ug-1 fs-xs px-2 py-1'
								className={`d-flex align-items-center ug-1 ${dataLayout !== 'table' && 'opacity-50'}`}
								onClick={() => setDataLayout('table')}
								active={dataLayout === 'table'}
								checked={dataLayout === 'table'}
							>
								<TbTableColumn strokeWidth={2.2} />
								<span className='fs-xxs lh-normal text-white'>TABLE</span>
							</Button>
						</ButtonGroup>

						<ButtonGroup className='d-flex'>
							<Button
								title='Clear Data'
								size='sm'
								variant='danger'
								className='d-flex align-items-center ug-1'
								onClick={handleClear}
								disabled={!data}
							>
								<TbX strokeWidth={2.6} />
								{/* <span className='fs-xxs lh-normal text-white'>CLEAR</span> */}
							</Button>

							{/* <Button size='sm' variant='success' className='d-flex align-items-center ug-1' disabled>
								<TbDatabase strokeWidth={2.2} />
								<span className='fs-xxs lh-normal text-white'>SAVE</span>
							</Button> */}
						</ButtonGroup>
					</div>

					{/* ADDITIONAL DATA ACTIONS */}
					<div className='d-flex flex-wrap justify-content-end ug-2'>
						{data && (
							<div className='d-flex justify-content-end flex-wrap ug-2'>
								{(dataSourceName === 'nflPicks' || dataSourceName === 'cfbPicks') && (
									<ButtonGroup className='ml-3'>
										<Button
											size='sm'
											variant='dark'
											className={`d-flex align-items-center ug-1 fs-xs px-2 py-1 ${
												filter_SourceFilters.includes('oddstrader') ? '' : 'opacity-50'
											}`}
											onClick={() => toggleSourceFilter('oddstrader')}
											active={filter_SourceFilters.includes('oddstrader')}
											checked={filter_SourceFilters.includes('oddstrader')}
										>
											<img className='h-15' src={logos.oddstrader} />
										</Button>

										<Button
											size='sm'
											variant='dark'
											className={`d-flex align-items-center ug-1 fs-xs px-2 py-1 ${
												filter_SourceFilters.includes('covers') ? '' : 'opacity-50'
											}`}
											onClick={() => toggleSourceFilter('covers')}
											active={filter_SourceFilters.includes('covers')}
											checked={filter_SourceFilters.includes('covers')}
										>
											<img className='h-16' src={logos.covers} />
										</Button>

										<Button
											size='sm'
											variant='dark'
											className={`d-flex align-items-center ug-1 fs-xs px-2 py-1 ${
												filter_SourceFilters.includes('pickswise') ? '' : 'opacity-50'
											}`}
											onClick={() => toggleSourceFilter('pickswise')}
											active={filter_SourceFilters.includes('pickswise')}
											checked={filter_SourceFilters.includes('pickswise')}
										>
											<img className='h-12' src={logos.pickswise} />
										</Button>

										<Button
											size='sm'
											variant='dark'
											className={`d-flex align-items-center ug-1 fs-xs px-2 py-1 ${
												filter_SourceFilters.includes('dunkelIndex') ? '' : 'opacity-50'
											}`}
											onClick={() => toggleSourceFilter('dunkelIndex')}
											active={filter_SourceFilters.includes('dunkelIndex')}
											checked={filter_SourceFilters.includes('dunkelIndex')}
										>
											<img className='h-15' src={logos.dunkelIndex} />
										</Button>
									</ButtonGroup>
								)}
							</div>
						)}

						<Button
							title='Table View'
							size='sm'
							variant='dark'
							className='d-flex align-items-center ug-1 opacity-75'
							onClick={() => setFilter_ViewLess((prev) => !prev)}
							active={dataLayout === 'table'}
							checked={dataLayout === 'table'}
						>
							{filter_ViewLess ? (
								<>
									<TbEyeMinus strokeWidth={2.2} />
									<span className='fs-xxs lh-normal text-white'>Viewing Less</span>
								</>
							) : (
								<>
									<TbEyePlus strokeWidth={2.2} />
									<span className='fs-xxs lh-normal text-white'>Viewing More</span>
								</>
							)}
						</Button>
					</div>
				</div>

				{/* SCRAPPED DATA */}
				{data && (
					<div className='scrapped-data-container mt-5'>
						<Row className='p-0'>
							{/* ODDSTRADER */}
							{dataSourceName === 'oddstrader' && (
								<Oddstrader
									data={data}
									dataSourceLeagueType={dataSourceLeagueType}
									dataLayout={dataLayout}
									dataViewLess={filter_ViewLess}
									logos={logos}
									links={links}
								/>
							)}

							{/* COVERS */}
							{dataSourceName === 'covers' && (
								<Covers
									data={data}
									dataSourceLeagueType={dataSourceLeagueType}
									dataLayout={dataLayout}
									dataViewLess={filter_ViewLess}
									logos={logos}
									links={links}
								/>
							)}

							{/* PICKSWISE */}
							{dataSourceName === 'pickswise' && (
								<Pickswise
									data={data}
									dataSourceLeagueType={dataSourceLeagueType}
									dataLayout={dataLayout}
									dataViewLess={filter_ViewLess}
									logos={logos}
									links={links}
								/>
							)}

							{/* DUNKELINDEX */}
							{dataSourceName === 'dunkelIndex' && (
								<DunkelIndex
									data={data}
									dataSourceLeagueType={dataSourceLeagueType}
									dataLayout={dataLayout}
									dataViewLess={filter_ViewLess}
									logos={logos}
									links={links}
								/>
							)}

							{/* NFL/CFB PICKS */}
							{(dataSourceName === 'nflPicks' || dataSourceName === 'cfbPicks') && (
								<>
									{/* ODDSTRADER */}
									{data.oddstrader && filter_SourceFilters.includes('oddstrader') && (
										<Oddstrader
											data={data.oddstrader}
											dataSourceLeagueType={dataSourceLeagueType}
											dataLayout={dataLayout}
											dataViewLess={filter_ViewLess}
											logos={logos}
											links={links}
										/>
									)}

									{/* COVERS */}
									{data.covers && filter_SourceFilters.includes('covers') && (
										<Covers
											data={data.covers}
											dataSourceLeagueType={dataSourceLeagueType}
											dataLayout={dataLayout}
											dataViewLess={filter_ViewLess}
											logos={logos}
											links={links}
										/>
									)}

									{/* PICKSWISE */}
									{data.pickswise && filter_SourceFilters.includes('pickswise') && (
										<Pickswise
											data={data.pickswise}
											dataSourceLeagueType={dataSourceLeagueType}
											dataLayout={dataLayout}
											dataViewLess={filter_ViewLess}
											logos={logos}
											links={links}
										/>
									)}

									{/* DUNKELINDEX */}
									{data.dunkelIndex && filter_SourceFilters.includes('dunkelIndex') && (
										<DunkelIndex
											data={data.dunkelIndex}
											dataSourceLeagueType={dataSourceLeagueType}
											dataLayout={dataLayout}
											dataViewLess={filter_ViewLess}
											logos={logos}
											links={links}
										/>
									)}
								</>
							)}
						</Row>
					</div>
				)}

				{/* NO SCRAPPED DATA */}
				{!data && (
					<Container className='p-0 mt-4'>
						<Row className='p-0'>
							<Col>
								<div
									className='card--2 d-flex align-items-center justify-content-center rounded p-2'
									style={{ height: '400px' }}
								>
									<p className='fs-sm fw-400 text-body-tertiary user-select-none'>Select a data source</p>
								</div>
							</Col>
						</Row>
					</Container>
				)}

				{/* SAVED DATA */}
				<Modal show={showUserPicksModal} onHide={() => setShowUserPicksModal(false)} size='xl' centered>
					<Modal.Header closeButton className='border-0'>
						<Modal.Title>Saved Picks</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{cachedPicks && cachedPicks.length > 0 ? (
							<Row>
								{cachedPicks.map((pick, index) => (
									<OddstraderCard
										key={`oddstrader-pick-${index}`}
										pick={pick}
										handleCacheDataInSession={handleCacheDataInSession}
										logos={logos}
									/>
								))}
							</Row>
						) : (
							<p>No data saved.</p>
						)}
					</Modal.Body>
					<Modal.Footer className='border-0'>
						<Button variant='secondary' onClick={() => setShowUserPicksModal(false)}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
};

export default SportsBettingPage;
