// DEPENDENCIES
import React, { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
// COMPONENTS
import CustomBlock from '../components/CustomBlock';
import LoadingOverlay from '../components/LoadingOverlay';
import FileUpload from '../components/FileUpload';
import useAppStore from '../stores/AppStore';

const FinancesPage = (props) => {
	const { appInfo, user } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [uploadMessage, setUploadMessage] = useState('');
	const uploadFile = useAppStore((state) => state.uploadFile);

	const handleFileUpload = async (file) => {
		// setIsLoading(true);
		// setUploadMessage('');
		// try {
		// 	await uploadFile(file);
		// 	setUploadMessage('File uploaded successfully');
		// } catch (error) {
		// 	setUploadMessage('File upload failed');
		// } finally {
		// 	setIsLoading(false);
		// }
	};

	return (
		<Container>
			{isLoading && <LoadingOverlay />}
			{uploadMessage && (
				<Row className='mb-3'>
					<Col>
						<Alert variant={uploadMessage.includes('successfully') ? 'success' : 'danger'}>{uploadMessage}</Alert>
					</Col>
				</Row>
			)}
			<Row className='bg-light rounded py-20 mb-5'>
				<Col lg={6}>
					<CustomBlock className='custom-block-test'>
						<FileUpload onFileUpload={handleFileUpload} />
					</CustomBlock>
				</Col>
			</Row>
			<Row className=''>
				<Col>
					<CustomBlock className='custom-block-test'>
						<p>Finance Test</p>
					</CustomBlock>
				</Col>
			</Row>
		</Container>
	);
};

export default FinancesPage;
