import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
// import authService from '../services/authService';
import useAppStore from '../stores/AppStore';
import { Container, Row, Col, Alert } from 'react-bootstrap';

const AccountPage = (props) => {
	const { appInfo, userInfo } = props;
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const { setUser, signIn, signUp, signOut } = useAppStore((state) => ({
		setUser: state.setUser,
		signIn: state.signIn,
		signUp: state.signUp,
		signOut: state.signOut,
	}));

	useEffect(() => {
		if (userInfo) {
			console.log(userInfo);
		}
	}, [userInfo]);

	const handleSignUp = async () => {
		setError('');
		try {
			const user = await signUp(email, password);
			setUser(user);
		} catch (error) {
			setError(error.message);
		}
	};

	const handleSignIn = async () => {
		setError('');
		try {
			const user = await signIn(email, password);
			setUser(user);
			console.log('NEW USER SIGN IN \n', user);
		} catch (error) {
			setError(error.message);
		}
	};

	const handleSignOut = async () => {
		try {
			await signOut();
			setUser(null);
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<Container fluid>
			{/* NO USER */}
			{!userInfo && (
				<Row className='justify-content-center'>
					<Col lg={8}>
						{error && <div className='alert alert-danger'>{error}</div>}
						<div className='mb-3'>
							<label htmlFor='email' className='form-label'>
								Email
							</label>
							<input
								type='email'
								className='form-control'
								id='email'
								placeholder='Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className='mb-3'>
							<label htmlFor='password' className='form-label'>
								Password
							</label>
							<input
								type='password'
								className='form-control'
								id='password'
								placeholder='Password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						{/* <button className='btn btn-primary me-2' onClick={handleSignUp}>
							Sign Up
						</button> */}
						<button className='btn btn-secondary' onClick={handleSignIn}>
							Sign In
						</button>
					</Col>
				</Row>
			)}

			{/* USER */}
			{userInfo && (
				<Row>
					<Col>
						<p className='lead'>Welcome, {userInfo.email}</p>
						<button className='btn btn-primary' onClick={handleSignOut}>
							Sign Out
						</button>
					</Col>
				</Row>
			)}
		</Container>

		// <div className='container mt-4'>

		// </div>
	);
};

export default AccountPage;
