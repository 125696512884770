// DEPENDENCIES
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import useAppStore from './stores/AppStore';
import './assets/styles/custom/reset.css';
// COMPONENTS
import Page from './pages/Page';
// PAGES
import { routesConfig, sportsBettingRoutesConfig, managementRoutesConfig } from './routes/routes';
import { supabase } from './supabase/supabase';

const App = () => {
	const { appInfo, user, setUser, appState } = useAppStore((state) => ({
		appInfo: state.appInfo,
		user: state.user,
		setUser: state.setUser,
		appState: state,
	}));

	// useEffect(() => {
	// 	const checkUser = async () => {
	// 		const {
	// 			data: { session },
	// 		} = await supabase.auth.getSession();
	// 		if (session) {
	// 			const { user } = session;
	// 			// Fetch user profile
	// 			const { data: profile, error } = await supabase.from('profiles').select('role').eq('user_id', user.id).single();
	// 			if (profile && !error) {
	// 				setUser({ ...user, role: profile.role });
	// 			}
	// 		}
	// 	};
	// 	checkUser();
	// }, [setUser]);

	const ProtectedRoute = ({ children }) => {
		if (!user) {
			return <Navigate to='/account' />;
		}
		return children;
	};

	return (
		<Router>
			<Page appInfo={appInfo} userInfo={user}>
				<Routes>
					{routesConfig.map(({ id, path, component: Component, isProtected }) => (
						<Route
							key={id}
							path={path}
							element={
								isProtected ? (
									<ProtectedRoute>
										<Component appInfo={appInfo} userInfo={user} />
									</ProtectedRoute>
								) : (
									<Component appInfo={appInfo} userInfo={user} />
								)
							}
						/>
					))}

					{sportsBettingRoutesConfig.map(({ id, path, component: Component, isProtected }) => (
						<Route
							key={id}
							path={path}
							element={
								isProtected ? (
									<ProtectedRoute>
										<Component appInfo={appInfo} userInfo={user} />
									</ProtectedRoute>
								) : (
									<Component appInfo={appInfo} userInfo={user} />
								)
							}
						/>
					))}

					{managementRoutesConfig.map(({ id, path, component: Component, isProtected }) => (
						<Route
							key={id}
							path={path}
							element={
								isProtected ? (
									<ProtectedRoute>
										<Component appInfo={appInfo} userInfo={user} />
									</ProtectedRoute>
								) : (
									<Component appInfo={appInfo} userInfo={user} />
								)
							}
						/>
					))}
				</Routes>
			</Page>
		</Router>
	);
};

export default App;
