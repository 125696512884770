// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
// COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';
import LoadingOverlay from '../../../components/LoadingOverlay';
// ASSETS
import { TbStar, TbStarFilled } from 'react-icons/tb';
// SERVICES & HELPERS
import * as espnHelper from '../../../helper/espnHelpers';

const Pickswise = (props) => {
	const { data, dataSourceLeagueType, dataLayout, dataViewLess, logos, links } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [filteredData, setFilteredData] = useState([]);

	const showCompletedEvents = false;

	// useEffect(() => {
	// 	if (data) {
	// 		console.log(`PICKSWISE DATA \n`, data);
	// 	}
	// }, []);

	useEffect(() => {
		const processPicks = async () => {
			setIsLoading(true);

			if (data) {
				const validPicks = await Promise.all(
					data.map(async (pick) => {
						const bestPick = pick.pickConfidence && pick.pickConfidence.every((star) => star === '*');

						// FETCH NFL EVENT DETAILS ONLY IF THE LEAGUE TYPE IS "NFL"
						let eventDetails = null;
						if (dataSourceLeagueType === 'nfl') {
							eventDetails = await getEvent(pick.team1Name || pick.team2Name);
						}

						// FORMAT EVENT DATE USING MOMENT
						const formattedEventDate =
							eventDetails && eventDetails.date
								? moment(eventDetails.date).format('ddd, MMM Do, h:mm A')
								: null;

						return {
							...pick,
							eventDetails: {
								...eventDetails,
								formattedDate: formattedEventDate,
							},
							bestPick: bestPick || false,
						};
					})
				);

				// FILTER OUT COMPLETED EVENTS BASED ON showCompletedEvents TOGGLE
				const filteredPicks = validPicks.filter(
					(pick) => showCompletedEvents || pick.eventDetails?.status?.type?.name !== 'STATUS_FINAL'
				);

				// SORT PICKS BY EVENT DATE
				const sortedPicks = filteredPicks.sort((a, b) => {
					const dateA = new Date(a.eventDetails?.date);
					const dateB = new Date(b.eventDetails?.date);
					return dateA - dateB;
				});

				setFilteredData(sortedPicks);
			}
			setIsLoading(false);
		};

		processPicks();
	}, [data, dataLayout, showCompletedEvents]);

	// FUNCTION TO GET NFL EVENT DETAILS BY TEAM ABBREVIATION
	const getEvent = async (teamAbr) => {
		const event = await espnHelper.getNFLEventByTeamAbbreviation(teamAbr);
		return event;
	};

	return (
		<>
			{!data || (isLoading && <LoadingOverlay />)}

			{filteredData.length > 0 &&
				filteredData.map((pick, index) => (
					<Col key={`pickswise-pick-${index}`} xs={12} sm={12} md={12} lg={6} className='mb-3'>
						<div
							className='card--2 rounded p-2 fs-xxs no-scrollbar card-scroll-shadow position-relative'
							style={{
								overflowY: 'scroll',
								maxHeight: '400px',
								...(pick.bestPick && {
									boxShadow:
										'rgba(0, 0, 0, 0.1) 0px -3em 3em inset, rgb(229, 32, 23, 0.4) 0px 0px 0px 2px, rgba(229, 32, 23, 0.4) 0.2em 0.2em 0.6em, rgba(229, 32, 23, 0.4) -0.2em -0.2em 0.6em',
								}),
							}}
						>
							{pick.pickConfidence && (
								<div className='position-absolute top-30 end-0 px-1'>
									<div className='d-flex align-items-center ug-1'>
										{pick.pickConfidence.map((star, index) =>
											star === '*' ? (
												<TbStarFilled
													key={`filled-${index}`}
													className='fs-xs'
													style={{ color: '#E52017' }}
												/>
											) : (
												<TbStarFilled key={`empty-${index}`} className='fs-xs' style={{ color: '#CBD5E1' }} />
											)
										)}
									</div>
								</div>
							)}

							<div className='card-content pickswise'>
								<div className='d-flex align-items-center ug-3'>
									<div className='d-flex flex-column align-items-center ug-1 mb-2'>
										{pick.team1Image && (
											<img src={pick.team1Image} className='team-image user-select-none h-45' />
										)}
										{pick.team1Name && <span className='fs-xxxs fw-500 text-muted'>{pick.team1Name}</span>}
									</div>
									<div className='d-flex flex-column align-items-center ug-1 mb-1'>
										{pick.team2Image && (
											<img src={pick.team2Image} className='team-image user-select-none h-45' />
										)}
										{pick.team2Name && <span className='fs-xxxs fw-500 text-muted'>{pick.team2Name}</span>}
									</div>
								</div>

								<div className='d-flex align-items-center flex-wrap ug-1 fw-600 mb-2 fs-xxs text-muted'>
									{/* <span className=''>{pick.gameDate}</span> */}
									{/* <span className=''>{pick.gameTime}</span> */}
									{pick.eventDetails.isLive === true && (
										<div className='d-flex align-items-center ug-1'>
											<span className='badge bg-danger text-white round fs-xxxs lh-normal px-1 py-0'>
												LIVE
											</span>
											<span className='lh-1'>{pick.eventDetails.eventStatusDetails}</span>
										</div>
									)}
									{pick.eventDetails.eventStatus === 'STATUS_SCHEDULED' && (
										<div className='d-flex align-items-center ug-1'>
											<span className='badge bg-success text-white round fs-xxxs lh-normal px-1 py-0'>
												UPCOMING
											</span>
											{/* <span className=''>{pick.gameDate}</span>
											<span className=''>{pick.gameTime}</span> */}
											<span className=''>{pick.eventDetails.formattedDate}</span>
										</div>
									)}
								</div>

								<img
									src={logos.pickswise}
									className='content-source position-absolute bg-dark p-1 px-2 round'
								/>

								{pick.pickType && (
									<div className='d-flex align-items-center flex-wrap ug-1 mb-1'>
										<span className='badge fs-xxs fw-500 px-2 py-0 lh-1 rounded-pill bg-dark-subtle bg-gradient text-body-secondary'>
											{pick.pickType}
										</span>
										<span className='badge fs-xxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light'>
											{pick.pickOutcome}
										</span>
									</div>
								)}

								{!dataViewLess && pick.reasoning && (
									<p className='fs-xxs fw-400 mb-2 p-1'>{pick.reasoning}</p>
								)}

								{/* {pick.pickAnalysis && (
									<a
										href={pick.pickAnalysis}
										target='_blank'
										rel='noopener noreferrer'
										className='fs-xxx fw-600 text-primary'
									>
										Read full analysis
									</a>
								)} */}
							</div>
						</div>
					</Col>
				))}
		</>
	);
};

export default Pickswise;
