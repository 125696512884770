// DEPENDENCIES
import React from 'react';
import '../assets/styles/custom/hamsterLoader.css';
// COMPONENTS

const LoadingOverlay = () => {
	// const { as = 'div', className, children } = props;
	// const [isLoading, setIsLoading] = useState(false);

	return (
		// <div className='loading-overlay'>
		// 	<p className=''>Loading...</p>
		// </div>

		<div className='overlay'>
			<div className='wheel-and-hamster'>
				<div className='wheel'></div>
				<div className='hamster'>
					<div className='hamster__body'>
						<div className='hamster__head'>
							<div className='hamster__ear'></div>
							<div className='hamster__eye'></div>
							<div className='hamster__nose'></div>
						</div>
						<div className='hamster__limb hamster__limb--fr'></div>
						<div className='hamster__limb hamster__limb--fl'></div>
						<div className='hamster__limb hamster__limb--br'></div>
						<div className='hamster__limb hamster__limb--bl'></div>
						<div className='hamster__tail'></div>
					</div>
				</div>
				<div className='spoke'></div>
			</div>
		</div>
	);
};

export default LoadingOverlay;
