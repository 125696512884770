import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const FileUpload = ({ onFileUpload }) => {
	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileChange = (e) => {
		setSelectedFile(e.target.files[0]);
	};

	const handleFileUpload = () => {
		if (selectedFile) {
			onFileUpload(selectedFile);
		}
	};

	return (
		<Form>
			<Form.Group controlId='formFile' className='mb-3'>
				<Form.Label>Upload Finance Document</Form.Label>
				<Form.Control type='file' onChange={handleFileChange} />
			</Form.Group>
			<Button variant='primary' onClick={handleFileUpload} disabled={!selectedFile}>
				Upload
			</Button>
		</Form>
	);
};

export default FileUpload;
