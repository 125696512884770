import axios from 'axios';
import db from '../stores/db';
import { v4 as uuidv4 } from 'uuid';
import { setLastFetchTime } from '../stores/dbQueries';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://ogzaniacpicks.com' : 'http://localhost:5000';

export const getNFLData = async () => {
	const dunkelData = await fetchDunkelIndexData();
	const oddstraderData = await fetchOddsTraderData();
	const coversData = await fetchCoversData();
	const pickswiseData = await fetchPickswiseData();
	const cbsSportsData = await fetchCBSSportsData();

	const events = [
		...dunkelData.events,
		...oddstraderData.events,
		...coversData.events,
		...pickswiseData.events,
		...cbsSportsData.events,
	];
	const picks = [
		...dunkelData.picks,
		...oddstraderData.picks,
		...coversData.picks,
		...pickswiseData.picks,
		...cbsSportsData.picks,
	];

	for (const event of events) {
		const awayTeam = event.competitions.awayTeam;
		const awayTeamRes = await getNFLTeamRecordByTeamId(awayTeam.id);
		console.log('AWAY TEAM RES\n', awayTeamRes);
		const awayTeamData = {
			...awayTeam,
			record: awayTeamRes?.team?.record,
			standingSummary: awayTeamRes?.team?.standingSummary,
		};

		const homeTeam = event.competitions.homeTeam;
		const homeTeamRes = await getNFLTeamRecordByTeamId(homeTeam.id);
		const homeTeamData = {
			...homeTeam,
			record: homeTeamRes?.team?.record,
			standingSummary: homeTeamRes?.team?.standingSummary,
		};

		event.competitions.awayTeam = awayTeamData;
		event.competitions.homeTeam = homeTeamData;
	}

	await db.events.bulkPut(events);
	await db.picks.bulkPut(picks);

	await setLastFetchTime('nfl');

	return { events, picks };
};

async function fetchDunkelIndexData() {
	const fetchURL = `${baseURL}/api/scrape/nfl/dunkelIndex/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cNFL | FETCHING DUNKEL INDEX',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Dunkel Index',
				league: 'nfl',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Dunkel Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Dunkel Index:', error);
	}

	return { events, picks };
}

async function fetchOddsTraderData() {
	const fetchURL = `${baseURL}/api/scrape/nfl/oddstrader/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cNFL | FETCHING ODDSTRADER',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Oddstrader',
				league: 'nfl',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Oddstrader Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Oddstrader:', error);
	}

	return { events, picks };
}

const fetchCoversData = async () => {
	const fetchURL = `${baseURL}/api/scrape/nfl/covers/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cNFL | FETCHING COVERS',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Covers',
				league: 'nfl',
				pickValue: item.pickValue || {
					analysisLink: item.pickValue?.analysisLink || null,
					displayText: item.pickValue?.displayText || '',
				},
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Covers Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.pickTeamLogo || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Covers:', error);
	}

	return { events, picks };
};

const fetchPickswiseData = async () => {
	const fetchURL = `${baseURL}/api/scrape/nfl/pickswise/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cNFL | FETCHING PICKSWISE',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Pickswise',
				league: 'nfl',
				pickValue: item.pickValue || {
					analysisLink: item.pickValue?.analysisLink || null,
					displayText: item.pickValue?.displayText || '',
				},
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Covers Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.pickTeamLogo || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Covers:', error);
	}

	return { events, picks };
};

const fetchCBSSportsData = async () => {
	const fetchURL = `${baseURL}/api/scrape/nfl/cbs/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cNFL | FETCHING CBS',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'CBS',
				league: 'nfl',
				pickValue: item.pickValue || {
					analysisLink: item.pickValue?.analysisLink || null,
					displayText: item.pickValue?.displayText || '',
				},
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Covers Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.pickTeamLogo || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Covers:', error);
	}

	return { events, picks };
};

async function getNFLTeamRecordByTeamId(teamId) {
	const url = `https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams/${teamId}`;

	try {
		const data = await axios.get(url);
		return data.data;
	} catch (error) {
		console.error('Error fetching team details:', error);
		return null;
	}
}

export const fetchBoxScoreByEventId = async (eventId) => {
	const fetchURL = `https://site.api.espn.com/apis/site/v2/sports/football/nfl/summary?event=${eventId}`;
	try {
		const response = await axios.get(fetchURL);

		// const boxscoreModel = {
		// 	id: eventId,
		// }
		return response.data;
	} catch (error) {
		console.error('Error fetching event boxscore:', error);
		return null;
	}
};

// ************************************************************************************************************** //

export const getTeamStatusByTeamId = async (teamId) => {
	const year = 2024;
	const seasonType = 2;

	// API URL FOR FETCHING TEAM STATISTICS
	const url = `https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/${year}/types/${seasonType}/teams/${teamId}/statistics`;

	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`Failed to fetch statistics for team ${teamId}. Status: ${response.status}`);
		}

		const data = await response.json();
		const stats = {};

		// Extract categories from the API data
		const categories = data.splits?.categories || [];

		// Iterate over each category dynamically and directly map stats with all properties
		categories.forEach((category) => {
			const categoryName = category.name || 'unknown';

			// Directly map the entire stat object by stat name
			stats[categoryName] = category.stats.reduce((acc, stat) => {
				acc[stat.name] = stat;
				return acc;
			}, {});
		});

		return stats;
	} catch (error) {
		console.error('Error fetching team stats:', error);
		throw error;
	}
};

export const getNFLRoster = async () => {
	try {
		// CHECK IF ROSTER EXISTS IN Dexie
		const storedRoster = await db.nflRoster.toArray();
		const lastFetched = await db.table('metadata').get('rosterTimestamp');

		// DEFINE REFRESH INTERVAL (e.g., 7 days)
		const refreshRateHours = 72;
		const refreshRateMS = refreshRateHours * 60 * 60 * 1000;
		const isDataStale = !lastFetched || Date.now() - lastFetched.timestamp > refreshRateMS;

		// IF ROSTER EXISTS AND IS NOT STALE, RETURN IT
		if (storedRoster.length > 0 && !isDataStale) {
			return storedRoster;
		}

		// FETCH ROSTER DATA FROM API IF NOT FOUND IN Dexie OR DATA IS STALE
		const TEAM_API = 'https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams';
		const response = await fetch(TEAM_API);
		const data = await response.json();
		const teams = data.sports[0].leagues[0].teams;

		console.log(teams[0]);

		let completeRoster = [];

		// ITERATE OVER EACH TEAM TO FETCH ROSTER
		for (let team of teams) {
			const teamId = team.team.id;
			const teamName = team.team.abbreviation;
			const teamLogo = `https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${teamName.toLowerCase()}.png&w=32&h=32&scale=crop&cquality=40&location=origin`;

			// FETCH TEAM ROSTER
			const rosterResponse = await fetch(`${TEAM_API}/${teamId}?enable=roster`);
			const rosterData = await rosterResponse.json();

			// MAP ATHLETES TO INCLUDE RELEVANT DETAILS
			const athletes = rosterData.team.athletes.map((athlete) => {
				if (athlete.id === '3915511') {
					console.log('[REF] Joe Burrow', athlete);
				}
				const athleteStatsLink = athlete.links[1].href || athlete.links?.find((link) => link.text === 'Stats')?.href;
				const athleteGameLogLink = athlete.links[3].href || athlete.links?.find((link) => link.text === 'Game Log')?.href;

				return {
					id: athlete.id,
					fullName: athlete.fullName,
					position: athlete.position.abbreviation,
					headshotURL: athlete.headshot?.href || null,
					team: teamName,
					teamImageURL: teamLogo,
					athleteGameLogLink,
				};
			});

			// ADD TO COMPLETE ROSTER
			completeRoster = completeRoster.concat(athletes);
		}

		// SAVE ROSTER TO Dexie
		await db.nflRoster.bulkPut(completeRoster);

		// UPDATE LAST FETCHED TIMESTAMP
		await db.table('metadata').put({ id: 'rosterTimestamp', timestamp: Date.now() });

		return completeRoster;
	} catch (error) {
		// LOG ERROR AND THROW
		console.error('Error fetching NFL teams or player data:', error);
		throw error;
	}
};

export const getNFLPlayerStats = async (playerId) => {
	// API SOURCE: https://github.com/nntrn/espn-wiki/wiki

	try {
		const year = 2024;
		const playerEventLogURL = `https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/${year}/athletes/${playerId}/eventlog`;
		const response = await fetch(playerEventLogURL);

		if (!response.ok) {
			throw new Error(`Failed to fetch event log. Status: ${response.status}`);
		}

		const data = await response.json();
		const events = data.events?.items || [];

		// FETCH STATISTICS FOR EACH GAME EVENT
		const detailedGameStats = await Promise.all(
			events.map(async (event) => {
				const eventStatisticsRef = event.statistics?.$ref;

				// IF STATISTICS REF EXISTS, FETCH DETAILED STATS
				if (eventStatisticsRef) {
					const statsResponse = await fetch(eventStatisticsRef);

					if (!statsResponse.ok) {
						throw new Error(`Failed to fetch statistics for event ${event.event.$ref}. Status: ${statsResponse.status}`);
					}

					const statsData = await statsResponse.json();

					// ORGANIZE STATS BY CATEGORY
					const categorizedStats = {};
					const statsSummary = {};

					statsData.splits.categories.forEach((category) => {
						const categoryName = category.displayName || category.name;

						// ORGANIZE ALL STATS IN THE CATEGORY
						categorizedStats[categoryName] = category.stats.reduce((acc, stat) => {
							acc[stat.displayName] = {
								value: stat.value,
								shortName: stat.shortDisplayName,
								abbreviation: stat.abbreviation,
								description: stat.description,
							};
							return acc;
						}, {});

						// CREATE A SIMPLIFIED SUMMARY CONTAINING ONLY STATS WITH A VALUE
						statsSummary[categoryName] = category.stats
							.filter((stat) => stat.value !== null && stat.value !== 0)
							.reduce((acc, stat) => {
								acc[stat.displayName] = stat; // STORE THE ENTIRE STAT OBJECT INSTEAD OF JUST THE VALUE
								return acc;
							}, {});
					});

					console.log(`\n\n[REF] STATS EVENT \n`, event, '\n', `[REF] STATS DATA \n`, statsData);

					return {
						gameId: event.event.$ref.split('/').pop(),
						gameDate: event.date,
						teamId: event.teamId,
						played: event.played,
						stats: categorizedStats, // FULL DETAILED STATS
						statsSummary, // SIMPLIFIED SUMMARY WITH COMPLETE STAT OBJECTS
					};
				}

				// RETURN BASIC INFO IF NO STATS REF IS FOUND
				return {
					gameId: event.event.$ref.split('/').pop(),
					gameDate: event.date,
					teamId: event.teamId,
					played: event.played,
					stats: null,
					statsSummary: null,
				};
			})
		);

		console.log(`[REF] DETAILED GAME STATS \n`, detailedGameStats);

		return detailedGameStats;
	} catch (error) {
		console.error('Error fetching player stats:', error);
		throw error;
	}
};
