import axios from 'axios';
import db from '../stores/db';
import { v4 as uuidv4 } from 'uuid';
import { setLastFetchTime } from '../stores/dbQueries';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://ogzaniacpicks.com' : 'http://localhost:5000';

export const getCBBData = async () => {
	const oddstraderData = await fetchOddsTraderData();
	const dunkelIndexData = await fetchDunkelIndexData();
	const cbsData = await fetchCBSSportsData();

	// SAFELY INITIALIZE EVENTS AND PICKS ARRAYS
	const events = [...oddstraderData.events, ...dunkelIndexData.events, ...cbsData.events];
	const picks = [...oddstraderData.picks, ...dunkelIndexData.picks, ...cbsData.picks];

	for (const event of events) {
		// INITIALIZE COMPETITIONS OBJECT TO AVOID NULL ERRORS
		const competitions = event.competitions || {};
		event.competitions = competitions;

		// NULL-CHECKING AND SETTING AWAY TEAM DATA
		const awayTeam = competitions.awayTeam || {};
		if (awayTeam.id) {
			const awayTeamRes = await getCBBTeamRecordByTeamId(awayTeam.id);
			const awayTeamData = {
				...awayTeam,
				record: awayTeamRes?.team?.record || null,
				standingSummary: awayTeamRes?.team?.standingSummary || null,
			};
			event.competitions.awayTeam = awayTeamData;
		} else {
			event.competitions.awayTeam = awayTeam;
		}

		// NULL-CHECKING AND SETTING HOME TEAM DATA
		const homeTeam = competitions.homeTeam || {};
		if (homeTeam.id) {
			const homeTeamRes = await getCBBTeamRecordByTeamId(homeTeam.id);
			const homeTeamData = {
				...homeTeam,
				record: homeTeamRes?.team?.record || null,
				standingSummary: homeTeamRes?.team?.standingSummary || null,
			};
			event.competitions.homeTeam = homeTeamData;
		} else {
			event.competitions.homeTeam = homeTeam;
		}
	}

	// FILTER OUT EVENTS OR PICKS WITHOUT A VALID ID BEFORE BULK PUTTING
	const validEvents = events.filter((event) => event.id);
	const validPicks = picks.filter((pick) => pick.id);

	// LOG INVALID ITEMS FOR DEBUGGING IF NEEDED
	if (validEvents.length < events.length) {
		console.warn(
			'Some events are missing an ID and were not saved:',
			events.filter((event) => !event.id)
		);
	}
	if (validPicks.length < picks.length) {
		console.warn(
			'Some picks are missing an ID and were not saved:',
			picks.filter((pick) => !pick.id)
		);
	}

	await db.events.bulkPut(validEvents);
	await db.picks.bulkPut(validPicks);

	await setLastFetchTime('cbb');

	return { events: validEvents, picks: validPicks };
};

async function fetchOddsTraderData() {
	const fetchURL = `${baseURL}/api/scrape/cbb/oddstrader/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cCBB | FETCHING ODDSTRADER',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Oddstrader',
				league: 'cbb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'Oddstrader Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Oddstrader:', error);
	}

	return { events, picks };
}

async function fetchDunkelIndexData() {
	const fetchURL = `${baseURL}/api/scrape/cbb/dunkelIndex/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		// console.log('FETCH DUNKEL INDEX \n', response);
		console.log(
			'%cCBB | FETCHING DUNKEL INDEX',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'Dunkel Index',
				league: 'cbb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis,
				pickLabel: item.pickLabel || 'Dunkel Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.imageUrl || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
			});
		});
	} catch (error) {
		console.error('Error fetching Dunkel Index:', error);
		if (error.response) {
			console.error(`Server responded with status ${error.response.status}: ${error.response.data.error}`);
		} else if (error.request) {
			console.error('No response received from server:', error.request);
		} else {
			console.error('Unexpected error:', error.message);
		}
	}

	return { events, picks };
}

async function fetchCBSSportsData() {
	const fetchURL = `${baseURL}/api/scrape/cbb/cbs/picks`;
	const events = [];
	const picks = [];

	try {
		const response = await axios.get(fetchURL);
		console.log(
			'%cCBB | FETCHING CBS SPORTS',
			'background: #d3d3d3; color: black; font-weight: bold; border-radius: 5px; padding: 2px 6px; font-size: 11px;',
			'\n',
			response.data
		);

		response.data.forEach((item) => {
			if (item.matchedTeamNextEvent) events.push(item.matchedTeamNextEvent);

			picks.push({
				id: item.id || uuidv4(),
				eventId: item.matchedTeamNextEvent?.id || null,
				source: 'CBS',
				league: 'cbb',
				pickValue: item.pickValue || null,
				teamId: item.matchedTeam?.id || null,
				analysis: item.analysis || null,
				pickLabel: item.pickLabel || 'CBS Pick',
				pickSourceURL: item.pickSourceURL || null,
				pickTeamLogo: item.pickTeamLogo || null,
				matchedTeam: item.matchedTeam || null,
				matchedTeamNextEvent: item.matchedTeamNextEvent || null,
				meta: item.meta || [],
			});
		});
	} catch (error) {
		console.error('Error fetching CBS Sports:', error);
		if (error.response) {
			console.error(`Server responded with status ${error.response.status}: ${error.response.data.error}`);
		} else if (error.request) {
			console.error('No response received from server:', error.request);
		} else {
			console.error('Unexpected error:', error.message);
		}
	}

	return { events, picks };
}

async function getCBBTeamRecordByTeamId(teamId) {
	const url = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/${teamId}`;

	try {
		const data = await axios.get(url);
		return data.data;
	} catch (error) {
		console.error(`Error fetching team details for team ID ${teamId}:`, error);
		return null;
	}
}

export const fetchBoxScoreByEventId = async (eventId) => {
	const fetchURL = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/summary?event=${eventId}`;
	try {
		const response = await axios.get(fetchURL);

		// const boxscoreModel = {
		// 	id: eventId,
		// }
		return response.data;
	} catch (error) {
		console.error('Error fetching event boxscore:', error);
		return null;
	}
};

export const updateEventStatuses = async (league) => {
	try {
		// Retrieve all events for the league from the database
		const events = await db.events.where('league').equals(league.toLowerCase()).toArray();

		if (!events || events.length === 0) {
			console.warn(`No events found for league ${league}.`);
			return;
		}

		// For each event, fetch the latest status and update it in the database
		await Promise.all(
			events.map(async (event) => {
				try {
					// Fetch the latest status for the event
					const url = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/summary?event=${event.id}`;
					const response = await axios.get(url);
					const data = response.data;

					// Extract the status from the response
					const status = data.header?.competitions?.[0]?.status;

					if (status) {
						// Update the status field of the event in the database
						await db.events.update(event.id, { status });

						console.log(`Updated status for event ID ${event.id}.`);
					} else {
						console.warn(`No status data for event ID ${event.id}.`);
					}
				} catch (error) {
					console.error(`Error updating status for event ID ${event.id}:`, error);
				}
			})
		);

		console.log(`Event statuses updated for league ${league}.`);
	} catch (error) {
		console.error(`Error updating event statuses for league ${league}:`, error);
	}
};

/* ****************************************************************************************************
BUILD CFB TEAMS LOOKUP
* BASED ON ESPN API: https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/[TEAM_ID]
* PREVIOUSLY RAN FROM [0, 6000] WITH ~1342 RESULTS
const buildCBBTeamsLookup = (async () => {
	const teamsLookup = {};

	for (let id = 0; id <= 10; id++) {
		try {
			const url = `https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/${id}`;
			const { data } = await axios.get(url);

			// CHECK IF THE API RESPONSE INDICATES A FAILURE
			if (data.code === 400 && data.message === 'Failed to get league teams summary') {
				console.log('Reached end of available team IDs. Stopping...');
				break;
			}

			const team = data.team;
			teamsLookup[team.slug] = {
				id: team?.id || null,
				uid: team?.uid || null,
				slug: team?.slug || null,
				name: team?.name || null,
				location: team?.location || null,
				displayName: team?.displayName || null,
				abbreviation: team?.abbreviation || null,
				logo: team?.logos?.[0]?.href || null,
				// conferenceId: (team?.groups?.isConference && team?.groups?.id) || null,
			};

			console.log(`${team.id} | ${team.location} ${team.name}`);
		} catch (error) {
			console.log(
				`${id} | ERROR | https://site.api.espn.com/apis/site/v2/sports/basketball/mens-college-basketball/teams/${id}`,
				error.message
			);
		}
	}

	console.log(JSON.stringify(teamsLookup, null, 2));

	return teamsLookup;
})();
**************************************************************************************************** */
