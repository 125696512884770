// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
// COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Countdown from '../../../components/Countdown';
// HELPERS & SERVICES

const DunkelIndex = (props) => {
	const { data, dataLayout, dataViewLess, logos, links } = props;
	const [isLoading, setIsLoading] = useState(false);

	// useEffect(() => {
	// 	if (data) {
	// 		console.log(`DUNKEL INDEX DATA \n`, data);
	// 	}
	// }, []);

	return (
		<>
			{!data || (isLoading && <LoadingOverlay />)}

			{data &&
				data.map((pick, index) => (
					<Col
						key={`dunkelindex-pick-${index}`}
						xs={12}
						sm={12}
						md={12}
						lg={6}
						xl={6}
						xxl={4}
						className='mb-3'
					>
						<div
							className='card--2 rounded p-2 fs-xxs no-scrollbar card-scroll-shadow position-relative'
							style={{
								overflowY: 'scroll',
								maxHeight: '300px',
								...(pick.isFeaturedPick && {
									boxShadow:
										'rgba(0, 0, 0, 0.1) 0px -3em 3em inset, rgb(23, 192, 220, 0.4) 0px 0px 0px 2px, rgba(23, 192, 220, 0.4) 0.2em 0.2em 0.6em, rgba(23, 192, 220, 0.4) -0.2em -0.2em 0.6em',
								}),
							}}
						>
							<div className='card-content dunkelIndex'>
								<img
									src={logos.dunkelIndex}
									className='content-source position-absolute bg-dark p-1 px-2 round'
								/>

								{/* MATCHUP */}
								<div className='d-flex flex-column ug-2 mt-1 mb-1'>
									<div className='d-flex flex-column ug-1 mt-2'>
										<div className='d-flex align-items-center ug-1'>
											{pick.team1Image && (
												<div
													className='team-image-container w-40 h-40'
													style={{
														backgroundImage: `url(${pick.team1Image})`,
														backgroundSize: 'contain',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
													}}
												/>
											)}
											{pick.team1Name && (
												<div className='d-flex flex-column'>
													<span className='fs-xxs fw-700'>{pick.team1Name}</span>
													<div className='d-flex align-items-center flex-wrap ug-1'>
														{pick.teams[0].dunkelPick && (
															<span className='badge fs-xxs fw-400 px-2 py-0 lh-1 rounded-pill bg-dark bg-gradient text-light fit-content'>
																Pick {pick.teams[0].dunkelPick}
															</span>
														)}
														<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
															Rank {pick.teams[0].dunkelRanking}
														</span>
														<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
															Rating {pick.teams[0].dunkelRating}
														</span>
													</div>
												</div>
											)}
										</div>
									</div>

									<div className='d-flex flex-column ug-1'>
										<div className='d-flex align-items-center ug-1'>
											{pick.team2Image && (
												<div
													className='team-image-container w-40 h-40'
													style={{
														backgroundImage: `url(${pick.team2Image})`,
														backgroundSize: 'contain',
														backgroundPosition: 'center',
														backgroundRepeat: 'no-repeat',
													}}
												/>
											)}
											{pick.team2Name && (
												<div className='d-flex flex-column'>
													<span className='fs-xxs fw-700'>{pick.team2Name}</span>
													<div className='d-flex align-items-center flex-wrap ug-1'>
														{pick.teams[1].dunkelPick && (
															<span className='badge fs-xxs fw-400 px-2 py-0 lh-1 rounded-pill bg-dark bg-gradient text-light fit-content'>
																Pick {pick.teams[1].dunkelPick}
															</span>
														)}
														<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
															Rank {pick.teams[1].dunkelRanking}
														</span>
														<span className='badge fs-xxxs fw-400 px-2 py-0 lh-1 rounded-pill bg-secondary bg-gradient text-light fit-content'>
															Rating {pick.teams[1].dunkelRating}
														</span>
													</div>
												</div>
											)}
										</div>
									</div>

									<div className='d-flex flex-column mb-2 pt-1'>
										{/* <span className='fs-xxs fw-500 lh-normal'>
											{moment(pick.time).format('ddd, MMM Do, h:mm A')}
										</span> */}
										{/* <Countdown eventTime={pick.time} /> */}

										<Countdown
											data={pick.time}
											countdown
											text
											countdownClassName='fs-xxs fw-600 lh-1 text-secondary-emphasis'
											textClassName='fs-xxxs text-muted fw-400 lh-1'
										/>
									</div>
								</div>

								{/* PREDICTIONS */}
								<div className='d-flex flex-wrap ug-2 mb-1'>
									{pick.predictions.dunkel && (
										<div className='d-flex flex-column flex-wrap ug-1'>
											<span className='fs-xxs fw-600 lh-normal'>Dunkel Prediction</span>
											{/* Render Dunkel Prediction Details */}
											{pick.predictions.dunkel.winningMargin && (
												<span className='badge fs-xxs fw-500 px-2 py-0 lh-1 rounded-pill bg-dark-subtle bg-gradient text-body-secondary fit-content'>
													Winning Margin: {pick.predictions.dunkel.winningMargin}
												</span>
											)}
											{pick.predictions.dunkel.totalScore && (
												<span className='badge fs-xxs fw-500 px-2 py-0 lh-1 rounded-pill bg-dark-subtle bg-gradient text-body-secondary fit-content'>
													Total Score: {pick.predictions.dunkel.totalScore}
												</span>
											)}
										</div>
									)}

									{pick.predictions.vegas && (
										<div className='d-flex flex-column flex-wrap ug-1'>
											<span className='fs-xxs fw-600 lh-normal'>Vegas Prediction</span>
											{/* Render Vegas Prediction Details */}
											{pick.predictions.vegas.winningMargin && (
												<span className='badge fs-xxs fw-500 px-2 py-0 lh-1 rounded-pill bg-dark-subtle bg-gradient text-body-secondary fit-content'>
													Winning Margin: {pick.predictions.vegas.winningMargin}
												</span>
											)}
											{pick.predictions.vegas.totalScore && (
												<span className='badge fs-xxs fw-500 px-2 py-0 lh-1 rounded-pill bg-dark-subtle bg-gradient text-body-secondary fit-content'>
													Total Score: {pick.predictions.vegas.totalScore}
												</span>
											)}
										</div>
									)}
								</div>

								{/* FEATURED PICK */}
								{!dataViewLess && pick.isFeaturedPick && (
									<div className='d-flex flex-column flex-wrap ug-1'>
										<span
											className='fs-xs fw-600 lh-normal'
											style={{ color: '#17C0DC', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)' }}
										>
											Featured Pick Information
										</span>
										{pick.featuredPickInformation !== '' && (
											<span className='fs-xxs lh-normal'>{pick.featuredPickInformation}</span>
										)}
									</div>
								)}
							</div>
						</div>
					</Col>
				))}
		</>
	);
};

export default DunkelIndex;
